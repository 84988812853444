<div id="worship-and-sermons" class="pane-content">
    <div class="panel-pane pane-block pane-block-14 no-title block">        
        <div style="margin-bottom:55px; width:100%">
            <h1 class="node-title" style="text-align: center; font-family:'Open Sans Condensed', Arial"> LOGIN </h1>
        </div>
        <div class="loginPanel clearfix">
            <div class="loginImage"></div>
            <div class="loginForm">
                <div class="elementMargin">
                    <div class="firstDiv">
                        <div class="errorMessage" *ngIf='apiError' style="text-align:center;">{{apiError}}</div>
                        <div class="textlabel">Email Address</div>
                        <input type="email" [ngClass]="emailClass" (keyup)="validateLogin($event)" id="email" [(ngModel)]='user.email' placeholder="Enter Email Address" />
                    </div>
                    <div [ngClass]="emailLabel">Please enter valid email!</div>
                    <div>
                        <div class="textlabel">Password</div>
                        <input type="password" [ngClass]="passwordClass" (keyup)="validateLogin($event)" id="password" [(ngModel)]='user.password' placeholder="Enter Password" />
                    </div>
                    <div [ngClass]="passwordLabel">Please enter password!</div>
                    <div class=" checkboxDiv">
                        <input id="checkbox-2" class="checkbox-custom" name="checkbox-2" type="checkbox"> <label class="checkbox-custom-label">Remember Me </label>
                    </div>

                    <div style="margin-top: 23px;">
                        <input type="button" (click)="login()" class="submitBtn" value="Login" style="float: left;" />
                        <div style="float:right; margin-right:12%; font-family: 'Open Sans Condensed', Arial; font-weight: 300; margin-top:5px;"><a style="color:#EC1C24; font-size:12px; font-weight:600; text-decoration:none" [routerLink]="['/forgotpassword']">FORGOT PASSWORD?</a></div>
                    </div>
                    <div style="clear:both; padding-top:20px; font-family: 'Open Sans Condensed', Arial; font-weight: 300;">
                            Don't have an account? 
                            <a style="color:#EC1C24; font-weight:600; text-decoration:none"  [routerLink]="['/register']">SIGN UP</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
