import { Component, OnInit } from '@angular/core';
import {ViewportScroller} from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private viewPort: ViewportScroller) { 
    console.log("scroll Position==>", this.viewPort.getScrollPosition())
   }
  title = 'CLCG';  

  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }

  scroll = (event): void => {
    //console.log("event==>", event)
    //console.log("event.target.scrollHeight==>", this.viewPort.getScrollPosition());
    //handle your scroll here
    //notice the 'odd' function assignment to a class field
    //this is used to be able to remove the event listener
  };

}
