import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { Router, ActivatedRoute } from "@angular/router";
import { ThisReceiver } from '@angular/compiler';


@Component({
  selector: 'app-admin-servcies',
  templateUrl: './admin-services.component.html',
  styleUrls: ['./admin-services.component.css','../admin-parent/bootstrap.css']
})
export class AdminServicesComponent implements OnInit {
  services:any;
  userId:any;
  header:string='Edit User Details';
  userdetails:any;
  filteredServices:any;
  typeFilter='0';
  constructor(private service:ServiceService, private route:Router, private activatedroute:ActivatedRoute) { }
  ngOnInit(): void {
    if(this.activatedroute.snapshot.paramMap.get('id')){
      this.userId = this.activatedroute.snapshot.paramMap.get('id')
      this.service.fetchuseradmin(this.userId).subscribe((data:any)=>{
        console.log("data==>", data)
        this.userdetails=data.user;
      })
      let filters='';
        filters+='id='+this.userId
      
      this.service.fetchservicesadmin(filters).subscribe((services:any)=>{
        this.services = services.services;
        console.log("users==>", services.services)
        this.filterServices();
      })
    } else {
      this.service.fetchallservicesadmin().subscribe((services:any)=>{
        this.services = services.services;
        console.log("users==>", services.services)
        this.filterServices();
      })
    }
    
  }

  deleteService(serviceId) {
    if(confirm("Are you sure you want to delete this service?")){
      let deletefilters='';
      deletefilters+='serviceId='+serviceId
      
      this.service.deleteserviceadmin(deletefilters).subscribe((services:any)=>{
        this.service.fetchuseradmin(this.userId).subscribe((data:any)=>{
          console.log("data==>", data)
          this.userdetails=data.user;
        })
        let filters='';
          filters+='id='+this.userId
        
        this.service.fetchservicesadmin(filters).subscribe((services:any)=>{
          this.services = services.services;
          console.log("users==>", services.services)
          this.filterServices();
        })
      })
    }    
  }  

  filterServices(){
    this.filteredServices=[];
    if(this.typeFilter == '0'){
      this.filteredServices = [...this.services];
      console.log("this.filteredServices==>", this.filteredServices)
      return;
    } 

    for(let a of this.services){
      if(a.type == this.typeFilter){
        this.filteredServices.push(a);
      }
    }
    console.log("this.filteredServices==>", this.filteredServices)
  }
}
