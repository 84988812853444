import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css','../admin-parent/bootstrap.css']
})
export class AdminDashboardComponent implements OnInit {

  constructor(private service:ServiceService) { }
  users:any;
  activatedUsers:number = 0;
  offeredServices:number = 0;
  requestedServices:number = 0;
  ngOnInit(): void {
    this.service.fetchusers().subscribe((users:any)=>{
      this.users = users.data;
      for(let usr of this.users){
        if(usr.isVerified){
          this.activatedUsers++;
        }
      }
      this.service.fetchservices().subscribe((data:any)=>{
        for(let dat of data.services){
          if(dat.type==1){
            this.offeredServices++;
          }else{
            this.requestedServices++;
          }
        }
      })
      console.log(this.users)
    })

  }

}
