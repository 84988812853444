<div id="worship-and-sermons" class="pane-content">
    <div class="panel-pane pane-block pane-block-14 no-title block">        
        <div style="margin-bottom:55px; width:100%">
            <h1 class="node-title" style="text-align: center; font-family:'Open Sans Condensed', Arial"> FORGOT PASSWORD </h1>
        </div>
        <div class="loginPanel clearfix">
            <div class="loginForm">
                <div class="errorMessage" *ngIf='apiError' style="text-align:center;  text-transform: none; margin-top:20px; color:green;">{{apiError}}</div>
                <div class="elementMargin">
                    
                    <div class="firstDiv">
                        
                        <div class="textlabel">Email Address</div>
                        <input type="email" [ngClass]="emailClass" (keyup)="validateLogin($event)" id="email" [(ngModel)]='user.email' placeholder="Enter Email Address" />
                    </div>
                    <div [ngClass]="emailLabel">Please enter valid email!</div>
                    

                    <div style="margin-top: 23px;" class="sermon-cta-buttons">
                        <input type="button" (click)="login()" [disabled]="disabled" class="submitBtn" value="SUBMIT" style="float: left;" />
                                
                        <a class="itunes-btn" [routerLink]="['/login']">Back to Login</a>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
