<div id="worship-and-sermons" class="pane-content">
    <div class="panel-pane pane-block pane-block-14 no-title block">        
        <app-login-header [user]="userDetails"></app-login-header>
        <div class="dashboardPanel clearfix">
            <div class="offered services" (click)="navigatePage('offered')">
                <div style="padding-top:30px;">
                    SERVICES OFFERED IN CLM<br/>
                    <div class="count">{{offeredServices&&offeredServices.length ? offeredServices.length : 0}}</div>
                </div>
                <div class="offeredServicesOperator"></div>
            </div>
            <div class="requested services" (click)="navigatePage('requested')">
                <div style="padding-top:30px;">
                    SERVICES REQUESTED IN CLM<br/>
                    <div class="count">{{requestedServices&&requestedServices.length ? requestedServices.length : 0}}</div>
                </div>
                <div class="requestedServicesTools"></div>
            </div>
            <div class="saledonation services" style="clear: both;" (click)="navigatePage('sale-donation')">
                <div style="padding-top:30px;">
                    SALE/DONATION SERVICES<br/>
                    <div class="count">{{saledonation&&saledonation.length ? saledonation.length : 0}}</div>
                </div>
                <div class="offeredServicesOperator"></div>
            </div>
            <div class="serviceSmall" style="margin-left:0px;"  (click)="navigatePage('my-services')">
                <div style="float:left;">MY CLM SERVICES</div>
                <div class="arrow">></div>
            </div>
            <div class="serviceSmall" (click)="navigatePage('offer')">
                <div style="float:left;">OFFER A SERVICE</div>
                <div class="arrow">></div>
            </div>
            <div class="serviceSmall" (click)="navigatePage('request')">
                <div style="float:left;">REQUEST A SERVICE</div>
                <div class="arrow">></div>
            </div>
            <div class="serviceSmall" style="clear:both; margin-left:0px;"  (click)="navigatePage('my-profile')">
                <div style="float:left;">MY PROFILE</div>
                <div class="arrow">></div>
            </div>
            <div class="serviceSmall" style="" (click)="navigatePage('change-password')">
                <div style="float:left;">CHANGE PASSWORD</div>
                <div class="arrow">></div>
            </div>
            <div style="clear: both; padding-top:50px; text-align: center; font-weight: bold;">
                For any questions on CLM please email <a href="mailto:marketplace@cedarlane.org">marketplace@cedarlane.org</a>
            </div>
        </div>
    </div>
</div>

