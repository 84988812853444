import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ServiceService } from '../services/service.service';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-request-service',
  templateUrl: './request-service.component.html',
  styleUrls: ['./request-service.component.css']
})
export class RequestServiceComponent implements OnInit {  
  serviceTypeClass= { text:true, textBox:true, error:false };
  serviceTypeError = { errorMessage:true, none:true };
  descriptionClass= { text:true, textArea:true, error:false };
  descriptionError = { errorMessage:true, none:true };
  phoneClass= { text:true, textBox:true, error:false };
  phoneError = { errorMessage:true, none:true };
  locationClass= { text:true, textBox:true, error:false };
  locationError = { errorMessage:true, none:true };
  serviceStatusClass = { text:true, textBox:true, error:false };
  serviceStatusError = { errorMessage:true, none:true };
  cancellationReasonClass= { text:true, textBox:true, smallTextArea:true, error:false };
  cancellationReasonError = { errorMessage:true, none:true };
  serviceTypes:any;
  userdetails:any;
  request={
    serviceType:'',
    description:'',
    location:'',
    phone:'',
    docs:'',
    status:'',
    price:0,
    serviceId:'',
    cancellationReason:''
  }
  apiSuccess='';
  docs=[];
  myService:any;

  constructor(private service:ServiceService, private router:Router, private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.service.fetchservicetypes().subscribe((data:any)=>{
      this.serviceTypes=data.serviceTypes;
    })

    this.service.fetchuserdetails().subscribe((data:any)=>{
      this.userdetails=data.user;
      if(!this.route.snapshot.paramMap.get('id')){
        this.request.phone = this.userdetails.phone;
      }
    })

    if(this.route.snapshot.paramMap.get('id')){
      let serviceId = this.route.snapshot.paramMap.get('id');
      this.service.myservices('').subscribe((data:any)=>{
        console.log("data==>", data)
        for(let a of data.services){
          if(a.userServiceId==serviceId){
            console.log("asdasdasd==>", a);
            this.myService=a;
            this.request.serviceType = a.serviceType;
            this.request.description = a.description;
            this.request.location = a.location;
            this.request.phone = a.phone;
            this.request.docs = a.docs;
            this.request.status = a.status;
            this.request.cancellationReason = a.cancellationReason;
            this.docs = a.docs?JSON.parse(a.docs):[];
          }
        }
        
      })
    }

  }

  deleteDoc(index){
    this.docs.splice(index, 1)
    this.request.docs = JSON.stringify(this.docs);
  }

  fileUploaded(event){
    let data = {};
    let files = event.srcElement.files;
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(i.toString(), files[i], files[i].name);
    }
    formData.append("data", JSON.stringify(data));
    this.service.postImage(formData).subscribe((fileUpload:any)=>{
      this.docs.push({fullUrl:environment.baseUrl+fileUpload.data, name:fileUpload.name});
      this.request.docs = JSON.stringify(this.docs);
    });
  }

  validateForm(event?){
    if(!event || event && event.keyCode!=13){
      if(!this.request.serviceType){
        this.serviceTypeClass.error=true;
        this.serviceTypeError.none=false;
      }else{
        this.serviceTypeClass.error=false;
        this.serviceTypeError.none=true;
      }
      if(!this.request.location){
        this.locationClass.error=true;
        this.locationError.none=false;
      }else{
        this.locationClass.error=false;
        this.locationError.none=true;
      }
      if(!this.request.phone){
        this.phoneClass.error=true;
        this.phoneError.none=false;
      }else{
        this.phoneClass.error=false;
        this.phoneError.none=true;
      }    
      if(!this.request.description){
        this.descriptionClass.error=true;
        this.descriptionError.none=false;      
      }else{
        this.descriptionClass.error=false;
        this.descriptionError.none=true;
      }
      if(!this.request.status){
        this.serviceStatusClass.error=true;
        this.serviceStatusError.none=false;
      } else {
        this.serviceStatusClass.error=false;
        this.serviceStatusError.none=true;
      }
      if(!this.request.cancellationReason){
        this.cancellationReasonClass.error=true;
        this.cancellationReasonError.none=false;
      } else {
        this.cancellationReasonClass.error=false;
        this.cancellationReasonError.none=true;
      }
    } else {
      this.submit();
    }
    
  }

  submit(){
    if(!this.request.serviceType){
      this.serviceTypeClass.error=true;
      this.serviceTypeError.none=false;
    }else{
      this.serviceTypeClass.error=false;
      this.serviceTypeError.none=true;
    }
    if(!this.request.location){
      this.locationClass.error=true;
      this.locationError.none=false;
    }else{
      this.locationClass.error=false;
      this.locationError.none=true;
    }
    if(!this.request.phone){
      this.phoneClass.error=true;
      this.phoneError.none=false;
    }else{
      this.phoneClass.error=false;
      this.phoneError.none=true;
    }    
    if(!this.request.description){
      this.descriptionClass.error=true;
      this.descriptionError.none=false;      
    }else{
      this.descriptionClass.error=false;
      this.descriptionError.none=true;
    }
    if(!this.request.status){
      this.serviceStatusClass.error=true;
      this.serviceStatusError.none=false;
    } else {
      this.serviceStatusClass.error=false;
      this.serviceStatusError.none=true;
    }
    if(!this.request.cancellationReason){
      this.cancellationReasonClass.error=true;
      this.cancellationReasonError.none=false;
    } else {
      this.cancellationReasonClass.error=false;
      this.cancellationReasonError.none=true;
    }

    if(!this.request.serviceType || !this.request.location || !this.request.phone || !this.request.description || !this.request.status){
      return
    }
    
    if(this.route.snapshot.paramMap.get('id')){
      this.request.serviceId = this.route.snapshot.paramMap.get('id');
      this.service.updateService(this.request).subscribe((data:any)=>{
        if(data.status=='success'){
          //this.router.navigate(['my-services']);
          this.apiSuccess='Request updated successfully!';
        }
      })
    } else{
      this.service.saverequest(this.request).subscribe((data:any)=>{
        if(data.status=='success'){
          this.request={
            serviceId:'',
            serviceType:'',
            description:'',
            location:'',
            phone:this.userdetails.phone,
            docs:'',
            status:'',
            price:0,
            cancellationReason:''
          }
          this.docs=[];
          this.apiSuccess='Request generated successfully!';
  
        }
      })
    }
    
  }
}
