import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from './../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  options: {
    //headers?: ,
    //observe?: 'body' | 'events' | 'response',
    //params?: HttpParams|{[param: string]: string | string[]},
    //reportProgress?: boolean,
    //responseType?: 'arraybuffer'|'blob'|'json'|'text',
    //withCredentials?: boolean,
  }
  constructor(private http: HttpClient) { }

  login(data){
    let configUrl = environment.baseUrl+'users/login';
    console.log("configUrl==>", configUrl)
    return this.http.post(configUrl, data);
  }

  forgotPassword(data){
    let configUrl = environment.baseUrl+'users/forgotpassword';
    console.log("configUrl==>", configUrl)
    return this.http.post(configUrl, data);
  }

  validateToken(token){
    let configUrl = environment.baseUrl+'users/validatetoken';
    let headers = {'Authorization': 'Bearer '+token};
    return this.http.get(configUrl, {headers:headers});
  }

  validateRefresh(data){
    let configUrl = environment.baseUrl+'users/validatereset';
    console.log("configUrl==>", configUrl)
    return this.http.post(configUrl, data);
  }

  resetPassword(data){
    let configUrl = environment.baseUrl+'users/resetpassword';
    console.log("configUrl==>", configUrl)
    return this.http.post(configUrl, data);
  }


}
