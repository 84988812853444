<div id="worship-and-sermons" class="pane-content">
    <section class="panel-pane pane-views-panes pane-ministry-panel-pane-1 block clearfix">
        <app-login-header [title]='"OFFER SERVICE"'></app-login-header>
        <div *ngIf="apiSuccess" style="color:green; text-align: center;">{{apiSuccess}}</div>
        <div *ngIf="!userdetails.profileImage" style="color:red; text-align: center;">Please upload profile pic first, from <a [routerLink]="['/my-profile']">My Profile</a> to Offer a service</div>
        <div class="serviceForm">
            <div class="left">
                <div class="textlabel" style="width:330px;">Service Type</div>
                <select [ngClass]="serviceTypeClass" (change)="validateForm($event)" [(ngModel)]="offer.serviceType">
                    <option value="">Select Service Type</option>
                    <option *ngFor="let a of serviceTypes" value="{{a.serviceId}}">{{a.serviceName}}</option>
                </select>
                <div [ngClass]="serviceTypeError">Please select service type</div>
            </div>      
            <div class="right clearfix">
                <div class="textlabel">Location</div>
                <input type="text" [ngClass]="locationClass" placeholder="Location" (keyup)="validateForm($event)" [(ngModel)]="offer.location" />
                <div [ngClass]="locationError">Please enter location</div>
            </div>        
            <div class="left">
                <div class="textlabel">Phone</div>
                <input type="text" [ngClass]="phoneClass" mask="(000)-000-0000" placeholder="Phone" (keyup)="validateForm($event)" [(ngModel)]="offer.phone" />
                <div [ngClass]="phoneError">Please enter phone</div>                
            </div>                      
            <div class="right clearfix">
                <div class="textlabel">Upload Image/PDF (upto 3 documents)</div>
                <input type="file" class="file" accept="application/pdf, image/*" style="clear:right; opacity:0; z-index: 100;" [disabled]="docs.length>2" (change)="fileUploaded($event)" placeholder="Upload Document" />
                <div style="margin-top:-38px; z-index:-1;">
                    <input type="text" class="text textBox" style="width:58%;" disabled value="{{docs && docs.length ? docs[docs.length-1].name:''}}" />
                    <a class="itunes-btn" style="background: #E8E7E7;" href="javascript:void(0)" style="float: right; margin-right:0px; padding:4.5px 17px;">BROWSE</a>
                </div>
                <div style="max-width:100%">
                    <div class="filesdiv" style="width:max-content" *ngFor="let file of docs let index=index"><a [href]="file.fullUrl" class="files" target="_blank">{{file.name}}</a>&nbsp;<a href="javascript:void(0)" class="filesX" (click)="deleteDoc(index)">X</a></div>
                </div>
            </div>
            <div class="left clearfix">
                <div class="textlabel" style="width:330px;">Status of Service</div>
                <select [ngClass]="serviceStatusClass" (change)="validateForm($event)" [(ngModel)]="offer.status">
                    <option value="">Select Service Status</option>
                    <option value="1">New</option>
                    <option value="2">In Progress</option>
                    <option value="3">Completed</option>
                </select>
                <div [ngClass]="serviceStatusError">Please select service status</div>
            </div>      
            <div class="left clearfix">
                <div class="textlabel">Total Commerce Value</div>
                <input type="number" class="text textBox" placeholder="Total Commerce Value" [(ngModel)]="offer.price" />
                <div class="errorMessage none">Please enter </div>
            </div>

            <div class="left clearfix">
                <div class="textlabel">Service Length</div>
                <input type="text" class="text textBox" placeholder="Service Length" [(ngModel)]="offer.serviceLength" />
                <div class="errorMessage none">Please enter </div>
            </div>
            
            <div class="full clearfix">
                <div class="textlabel">Service Description</div>
                <textarea name="serviceDesc" [ngClass]="descriptionClass" (keyup)="validateForm()" [(ngModel)]="offer.description" placeholder="Service Description"></textarea>
                <div [ngClass]="descriptionError">Please enter service description</div>
            </div>

            <div *ngIf="apiSuccess" style="color:green; text-align: center;">{{apiSuccess}}</div>
            <div *ngIf="!userdetails.profileImage" style="color:red; text-align: center;">Please upload profile pic first, from <a [routerLink]="['/my-profile']">My Profile</a> to Offer a service</div>

            <div style="padding-top:10px; width: auto; overflow:auto; margin:0 auto;" class="ctas clearfix"> 
                <div class="sermon-cta-buttons" *ngIf="userdetails.profileImage">
                    <a href="javascript:void(0)" (click)="submit()">SUBMIT</a>
                    <a [routerLink]="['/dashboard']" *ngIf="!myService" class="itunes-btn" style="background:#E8E7E7">CANCEL</a>
                    <a [routerLink]="['/my-services']" *ngIf="myService" class="itunes-btn" style="background:#E8E7E7">BACK</a>
                </div>
            </div>
        </div>
    </section>
</div>