import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AdminUsersComponent } from '../admin-users/admin-users.component';
import { ServiceService } from '../services/service.service';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-admin-add-servicetype',
  templateUrl: './admin-add-servicetype.component.html',
  styleUrls: ['./admin-add-servicetype.component.css']
})
export class AdminAddServiceTypeComponent implements OnInit {  
  serviceNameClass= { text:true, textBox:true, error:false };
  serviceNameError = { errorMessage:true, none:true };
  serviceTypes:any;
  userdetails:any;
  username='';
  servicetype={
    serviceName:'',
    serviceId:''
  }
  userId='';
  docs=[];
  constructor(private service:ServiceService, private router:Router, private route:ActivatedRoute) { }

  ngOnInit(): void {

    if(this.route.snapshot.paramMap.get('id')){
      let serviceId = this.route.snapshot.paramMap.get('id');
      this.servicetype.serviceId = serviceId;
      let filters='';
      filters+='id='+this.userId
      this.service.fetchservicetypes(filters).subscribe((data:any)=>{
        console.log("data==>", data)
        for(let a of data.serviceTypes){
          if(a.serviceId==serviceId){
            //this.myService=a;
            this.servicetype.serviceName = a.serviceName;
          }
        }
        
      })
    }

  }

  /*deleteDoc(index){
    this.docs.splice(index, 1)
    this.offer.docs = JSON.stringify(this.docs);
  }*/

  

  validateForm(){
    if(!this.servicetype.serviceName){
      this.serviceNameClass.error=true;
      this.serviceNameError.none=false;
    }else{
      this.serviceNameClass.error=false;
      this.serviceNameError.none=true;
    }    
  }

  submit(){
    if(!this.servicetype.serviceName){
      this.serviceNameClass.error=true;
      this.serviceNameError.none=false;      
      return;
    }else{
      this.serviceNameClass.error=false;
      this.serviceNameError.none=true;
    }
    
    
    if(this.route.snapshot.paramMap.get('id')){
      this.servicetype.serviceId = this.route.snapshot.paramMap.get('id');
      this.service.updateServiceType(this.servicetype).subscribe((data:any)=>{
        if(data.status=='success'){
          this.router.navigate(['admin/servicecategories']);
        }
      })
    } else {
      this.service.createServiceType(this.servicetype).subscribe((data:any)=>{
        if(data.status=='success'){
          this.router.navigate(['admin/servicecategories']);
        }
      })
    }    
  }

}
