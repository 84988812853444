import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  apiError = '';
  emailClass={textBox:true, error:false};
  passwordClass={textBox:true, error:false};
  emailLabel={errorMessage:true, none: true};
  passwordLabel={errorMessage:true, none: true}
  user={
    email:'',
    password:''
  }
  constructor(private loginService: LoginService, private router: Router) {
    if(localStorage.getItem('token')){
      this.loginService.validateToken(localStorage.getItem('token')).subscribe((token:any) => {
        console.log("token==>", token.status)
        if(token && token.status=='success'){
          this.router.navigate(['dashboard'])
        } else{
          localStorage.removeItem('token')
        }
      })
    }
   }
  token={};
  ngOnInit(): void {
    
  }

  login(){
    if(!this.user.email){
      this.emailClass.error=true;
      this.emailLabel.none=false;
    } else {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email)){        
        this.emailLabel.none=false;
        this.emailClass.error=true;
      }
    }
    if(!this.user.password){
      this.passwordClass.error=true;   
    }
    if(!this.user.password || !this.user.email || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email)){
      return;
    }
    this.user.email = this.user.email.toLowerCase();
    this.loginService.login(this.user).subscribe((token:any) => {
      console.log("token==>", token.status)
      if(token && token.status=='success'){
        localStorage.setItem('token', token.token);
        this.router.navigate(['dashboard'])
      }else{
        this.user.password='';
        this.apiError = "Email or Password is incorrect!";
        setTimeout(()=>{
          this.apiError='';
        }, 3000)
      }
    })
  }
  validateLogin(event){
    if(event.keyCode!=13){
      if(event.target.id=='email'){
        if(this.user.email){
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email)){
            this.emailLabel.none=true;
            this.emailClass.error=false;
          }else{
            this.emailLabel.none=false;
            this.emailClass.error=true;
          }
        }else{
          this.emailClass.error=true;
        }
      } else if(event.target.id=='password'){
        if(this.user.password){
          this.passwordClass.error=false;  
        }else{
          this.passwordClass.error=true;  
        }
      }
    } else {
      this.login();
    }    
  }
}
