<div class="appfooter">
  <div class="panel-separator"></div>
  <div class="panel-pane pane-panels-mini pane-site-footer no-title block">
      <div class="pane-inner clearfix">
        
                
        
        <div class="pane-content">
          <div class="three-3x33 at-panel panel-display clearfix" id="mini-panel-site_footer">
        <div class="region region-three-33-first">
        <div class="region-inner clearfix">
          <div class="panel-pane pane-block pane-block-2 no-title block">
      <div class="pane-inner clearfix">
        
                
        
        <div class="pane-content">
          <img src="/assets/images/cedar-lane-centered-logo.svg">     </div>
    
        
            
      </div>
    </div>
        </div>
      </div>
      <div class="region region-three-33-second">
        <div class="region-inner clearfix">
          <div class="panel-pane pane-block pane-block-3 no-title block">
      <div class="pane-inner clearfix">
        
                
        
        <div class="pane-content">
          <h3>Cedar Lane Unitarian Universalist Church</h3><p><span style="font-size:18px;">9601 Cedar Lane Bethesda, MD 20814</span></p><p><span style="font-size:18px;"><a href="tel:301-493-8300">Tel: 301-493-8300</a></span></p><p><span style="font-size:18px;">Email: <a href="mailto:office@CedarLane.org">office@CedarLane.org</a></span></p><p><span style="font-size:14px;">©  </span>&nbsp;<span style="font-size:14px;">Cedar Lane Unitarian Universalist Church</span></p><p><a href="http://www.theoryonedesign.com"><span style="font-size:14px;">Site Credits</span></a></p>     </div>
    
        
            
      </div>
    </div>
        </div>
      </div>
      <div class="region region-three-33-third">
        <div class="region-inner clearfix">
          <div class="panel-pane pane-block pane-block-4 no-title block">
      <div class="pane-inner clearfix">
        
                
        
        <div class="pane-content">
          <h4>WEEKLY WORSHIP</h4><p>From Memorial Day weekend through Labor Day weekend, join us every Sunday for 11 am YouTube Livestream.</p>     </div>
    
        
            
      </div>
    </div>
    <div class="panel-separator"></div><div class="panel-pane pane-block pane-block-5 no-title block">
      <div class="pane-inner clearfix">
        
                
        
        <div class="pane-content">
          <h4>STAY IN TOUCH</h4><ul><li><a href="https://www.facebook.com/cedarlaneuu/" id="fb-logo" target="_blank">Facebook</a></li><li><a href="https://twitter.com/CedarLaneUU" id="twitter-logo" target="_blank">Twitter</a></li><li><a href="https://www.youtube.com/channel/UCrWfUStKAIoltonXqK5oriQ" id="youtube-logo" target="_blank">YouTube</a></li><li><a href="https://www.instagram.com/cedarlaneuuchurch/" id="instagram-logo" target="_blank">Instagram</a></li></ul>     </div>
    
        
            
      </div>
    </div>
    <div class="panel-separator"></div><div class="panel-pane pane-block pane-block-6 no-title block">
      <div class="pane-inner clearfix">
        
                
        
        <div class="pane-content">
          <p><a href="https://visitor.r20.constantcontact.com/manage/optin?v=001AFZaZhfZL-RHVAIrrQDhkTvCmTu2Sikbmt0Y41keaTpyRUy2R74Mtve2aYKuiZi1e7V3QYZiVs8nEDULZVImgDgnzGvKChvKy8juwEYlVEpMzRAqIVzZP8bGLVTXvihLqgfk3VGvM61N005_s8iRAO3lkCRKgJL9" target="_blank">JOIN OUR ENEWS LIST</a></p>     </div>
    
        
            
      </div>
    </div>
    <div class="panel-separator"></div><div class="panel-pane pane-block pane-block-16 no-title block">
      <div class="pane-inner clearfix">
        
                
        
        <div class="pane-content">
          <p><a href="https://smile.amazon.com/ch/52-0629773" target="_blank"><img src="/assets/images/Amazon_Smile_logo.png"></a></p>     </div>
    
        
            
      </div>
    </div>
        </div>
      </div>
      </div>
        </div>
    
        
            
      </div>
  </div>
</div>