<div id="worship-and-sermons" class="pane-content">
    <h3 style="float:left"><a routerLink="/admin">Dashboard</a> > <a routerLink="/admin/servicecategories">Service Categories</a> > {{servicetype.serviceId?"Edit":"Add"}}</h3>
    <div class="panel-pane pane-block pane-block-14 no-title block">        
        <div style="margin-bottom:55px; width:100%">
            <h1 class="node-title" style="text-align: center; font-family:'Open Sans Condensed', Arial"> {{servicetype.serviceId?"EDIT":"ADD"}} A SERVICE CATEGORY </h1>
        </div>
        <div class="registerPanel clearfix">
            <!--<div class="registerImage"></div>-->
            <div class="registerForm">
                <div class="elementMargin">
                    <div class="firstDiv">
                        <div class="textlabel">Service Category Name</div>
                        <input type="text" [ngClass]="serviceNameClass" id="fs" (keyup)="validateForm()" [(ngModel)]='servicetype.serviceName' placeholder="Service Category Name" />
                    </div>
                    <div [ngClass]="serviceNameError">Please enter service category name!</div>   
                    
        
                    <div style="padding-top:10px; width: auto; overflow:auto; margin:0 auto;" class="ctas clearfix"> 
                        <div class="sermon-cta-buttons">
                            <a href="javascript:void(0)" (click)="submit()">SUBMIT</a>
                            <a [routerLink]="['/admin/servicecategories']" class="itunes-btn" style="background:#E8E7E7">CANCEL</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>