<h1 style="clear:both; text-align: center; font-family: 'Open Sans Condensed', Arial;">Service Categories</h1>
<div style="margin: 15px 0; float:right"><input type="button" value="Add a Service Category" routerLink='/admin/servicecategories/add' class="submitBtn" style="color: #fff; font-family: 'Open Sans Condensed', Arial;"></div>
<h3><a routerLink="/admin/">Dashboard</a> > Service Categories</h3>
<table class="table">
    <thead class="thead-dark">
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Created On</th>
            <th scope="col">Actions</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let service of servicetypes; let i=index">
            <th scope="row">{{i+1}}</th>
            <td>{{service.serviceName}}</td>
            <td>{{service.createdAt | date:'medium'}}</td>
            <td><a routerLink='/admin/servicecategories/edit/{{service.serviceId}}'>Edit</a> <!--|&nbsp; <a href="javascript:void(0)" (click)="deleteService(service.userServiceId)">Delete</a>--></td>
        </tr>
    </tbody>
</table>