<nav class="navbar navbar-inverse">
  <div class="container-fluid">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>                        
      </button>
      <a class="navbar-brand" routerLink='/admin'>Cedar Lane Marketplace - Admin</a>
    </div>
    <div class="collapse navbar-collapse" id="myNavbar">
      <ul class="nav navbar-nav">
        <li [ngClass]="homeRoute"><a [routerLink]="['/admin']">Home</a></li>
        <li [ngClass]="userRouteClass"><a [routerLink]="['/admin/users']">Users</a></li>
        <li [ngClass]="serviceCategoryRouteClass"><a [routerLink]="['/admin/servicecategories']">Service Categories</a></li>
        <li [ngClass]="servicesRouteClass"><a [routerLink]="['/admin/services']">Services</a></li>
        <li [ngClass]="cmsRouteClass"><a [routerLink]="['/admin/cms']">Home Page CMS</a></li>
        <!--<li><a routerLink='/admin/services'>Services</a></li>
        <li><a href="#">Contact</a></li>-->
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li><a href="javascript:void(0)" (click)="logout()"> Logout</a></li>
      </ul>
    </div>
  </div>
</nav>
