import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css','../admin-parent/bootstrap.css']
})
export class AdminUsersComponent implements OnInit {
  users:any;
  header:string='Edit User Details';
  constructor(private service:ServiceService, private route:Router) { }
  ngOnInit(): void {
    this.service.fetchusers().subscribe((users:any)=>{
      this.users = users.data;
      console.log(this.users)
    })
  }
  approve(userId){
    console.log("userId==>", userId)
    this.service.updateActivation({userId:userId, isVerified:1}).subscribe((data:any)=>{
      this.service.fetchusers().subscribe((users:any)=>{
        this.users = users.data;
        console.log(this.users)
      })
    })
  }

  delete(userId){
    if(confirm("Are you sure you want to delete this user?")){

      this.service.deleteuseradmin('userId='+userId).subscribe((data:any)=>{
        alert("User deleted successfully!");
        this.service.fetchusers().subscribe((users:any)=>{
          this.users = users.data;
          console.log(this.users)
        })
      })
    }
  }
}
