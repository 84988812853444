import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { RegisterService } from '../register.service';
import { Router } from "@angular/router";
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  withError = {textBox:true, error:true};
  withoutError = {textBox:true, error:false};
  fsClass = this.withoutError;
  lsClass = this.withoutError;
  emailClass = this.withoutError;
  phoneClass = this.withoutError;
  passwordClass = this.withoutError;
  confirmClass = this.withoutError;
  profileClass = this.withoutError;
  emailLabel = {errorMessage:true, none:true};
  confirmLabel = {errorMessage:true, none:true};
  profileLabel = {errorMessage:true, none:true};
  user={
    password:'',
    confirm:'',
  }
  file='';
  success=false;
  constructor(private registerService: RegisterService, private router: Router, private service: ServiceService) {
    
  }

  ngOnInit(): void {
    
  }
  register(){

    if(!this.user.password){
      this.passwordClass=this.withError;
      return;
    }else{
      this.passwordClass=this.withoutError;
    }
    if(!this.user.confirm){
      this.confirmClass=this.withError;
      this.confirmLabel.none = true;
      return;
    }else{
      if(this.user.password!=this.user.confirm){
        this.confirmClass=this.withError;
        this.confirmLabel.none = false;
        return;
      } else{
        this.confirmClass=this.withoutError;
        this.confirmLabel.none = true;
      }
    }

    this.service.updateProfile(this.user).subscribe((data:any) => {
      if(data && data.status=='success'){
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        this.success=true
        //this.router.navigate(['dashboard'])
      } 
    })
  }

  validateRegister(event){
    if(event.target.id=='password'){
      if(!this.user.password){
        this.passwordClass=this.withError;
      }else{
        this.passwordClass=this.withoutError;
      }
    } else if(event.target.id=='confirm'){
      if(!this.user.confirm){
        this.confirmClass=this.withError;
        this.confirmLabel.none = true;
      }else{
        if(this.user.password!=this.user.confirm){
          this.confirmClass=this.withError;
          this.confirmLabel.none = false;
        } else{
          this.confirmClass=this.withoutError;
          this.confirmLabel.none = true;
        }
      }
    }
  }

}
