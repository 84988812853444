
<h1 style="text-align: center; font-family: 'Open Sans Condensed', Arial;">Users</h1>
<h3 style="float:left"><a routerLink="/admin">Dashboard</a> > Users</h3>
<div style="margin: 15px 0; float:right"><input type="button" value="Add User" routerLink='/admin/users/adduser' class="submitBtn" style="color: #fff; font-family: 'Open Sans Condensed', Arial;"></div>
<table class="table">
    <thead class="thead-dark">
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>            
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let user of users; let i=index">
            <th scope="row" >{{i+1}}</th>
            <td>{{user.firstName +' '+ user.lastName}}</td>
            <td>{{user.email}}</td>
            <td>{{user.phone}}</td>
            <td>{{user.isVerified?'Approved':''}}<a href="javascript:void(0)" *ngIf="!user.isVerified" (click)="approve(user.userId)">Approve</a></td>
            <td><a routerLink='/admin/users/edituser/{{user.userId}}'>Edit</a> |&nbsp; <a routerLink='/admin/services/{{user.userId}}'>List Services</a> | <a href="javascript:void(0)" (click)="delete(user.userId)">Delete User</a></td>
        </tr>
    </tbody>
</table>