<div id="worship-and-sermons" class="pane-content">
    <h3 style="float:left"><a routerLink="/admin">Dashboard</a> > <a routerLink="/admin/users">Users</a> > {{header}}</h3>
    <div class="panel-pane pane-block pane-block-14 no-title block">        
        <div style="margin-bottom:55px; width:100%">
            <h1 class="node-title" style="text-align: center; font-family:'Open Sans Condensed', Arial"> {{header}} </h1>
        </div>
        <div class="registerPanel clearfix">
            <!--<div class="registerImage"></div>-->
            <div class="registerForm">
                <div class="elementMargin">
                    <div class="errorMessage" *ngIf="apiError">{{apiError}}</div>
                    <div class="firstDiv">
                        <div class="textlabel">First Name</div>
                        <input type="text" [ngClass]="fsClass" id="fs" (keyup)="validateRegister($event)" [(ngModel)]='user.firstName' placeholder="First Name" />
                    </div>
                    <div class="errorMessage none">Please enter valid email address!</div>
                    <div>
                        <div class="textlabel">Last Name</div>
                        <input type="text" [ngClass]="lsClass" id="ls" (keyup)="validateRegister($event)" [(ngModel)]='user.lastName' placeholder="Last Name" />
                    </div>
                    <div class="errorMessage none">Please enter valid email address!</div>
                    <div>
                        <div class="textlabel">Upload Profile Image</div>
                        <input type="file" class="file" accept="application/pdf, image/*" style="clear:right; opacity:0; z-index: 100;" (change)="fileUploaded($event)" placeholder="Upload Document" />
                        <div style="margin-top:-38px; z-index:-1;">
                            <input type="text" [ngClass]="profileClass" style="width:40%;" disabled value="{{file}}" />
                            <a class="itunes-btn" href="javascript:void(0)" style="margin-left:10px; padding:4.5px 0px; background: #E8E7E7; width:30%; text-align: center; vertical-align: sub;">BROWSE</a>
                            <img [src]="user.profileImage" style="width:8%; max-height:50px; vertical-align: bottom;">
                        </div>
                        
                    </div>
                    <div [ngClass]="profileLabel">Please upload profile image!</div>
                    <div>
                        <div class="textlabel">Email Address</div>
                        <input type="email" [ngClass]="emailClass"  [disabled]="isEdit" id="email" (keyup)="validateRegister($event)" [(ngModel)]='user.email' placeholder="Email Address" />
                    </div>
                    <div [ngClass]="emailLabel">Please enter valid email address!</div>
                    <div>
                        <div class="textlabel">Phone</div>
                        <input type="text" [ngClass]="phoneClass" [disabled]="isEdit" id="phone" (keyup)="validateRegister($event)" [(ngModel)]='user.phone' placeholder="Phone" />
                    </div>
                    <div class="errorMessage none">Please enter valid email address!</div>
                    <div *ngIf="!isEdit">
                        <div class="textlabel">Set Password</div>
                        <input type="checkbox"  [disabled]="isEdit" (change)="checkVal($event)" id="setPass" [(ngModel)]='user.setPass' />
                    </div>
                    <div *ngIf="user.setPass || isEdit">
                        <div class="textlabel">Password</div>
                        <input type="password" [ngClass]="passwordClass" id="password" (keyup)="validateRegister($event)" [(ngModel)]='user.password' placeholder="Password" />
                    </div>
                    <div class="passowrdLabel" class="errorMessage none" *ngIf="user.setPass">Please enter Password!</div>

                    <div *ngIf="user.setPass">
                        <div class="textlabel">Email Content      -> <strong ngNonBindable>*Do not remove {{firstName}} and {{password}} from the text</strong></div>
                        <textarea type="password" [ngClass]="emailContentClass" [disabled]="isEdit" id="password" (keyup)="validateRegister($event)" [(ngModel)]='user.emailContent' ></textarea>
                    </div>
                    <div class="passowrdLabel" class="errorMessage none" *ngIf="user.setPass">Please enter Password!</div>
                 
                    <div style="margin-top: 15px;">
                        <input type="button" (click)='register()' (keyup)="validateRegister($event)" class="submitBtn" value="SAVE" style="float: left;" />
                        <input type="button" (click)='goback()' class="submitBtn" value="CANCEL" style="float: left;" />
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>
</div>
