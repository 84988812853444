

<div class="serviceSmall" style="margin-left:0px;" *ngIf="users">
    <div><div style="float: left;">USERS</div>  <div style="float: right; margin-right:20px; font-weight:bold">{{users.length}}</div></div>
</div>
<div class="serviceSmall"  *ngIf="users">
    <div><div style="float: left;">UNVERIFIED USERS</div> <div style="float: right; margin-right:20px; font-weight:bold">{{users.length - activatedUsers}}</div></div>
</div>
<div class="serviceSmall">
    <div><div style="float:left;">OFFERED SERVICES</div> <div style="float: right; margin-right:20px; font-weight:bold">{{offeredServices}}</div></div>
</div>
<div class="serviceSmall">
    <div><div style="float:left;">REQUESTED SERVICES</div> <div style="float: right; margin-right:20px; font-weight:bold">{{requestedServices}}</div></div>
</div>