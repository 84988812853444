import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-services',
  templateUrl: './my-services.component.html',
  styleUrls: ['./my-services.component.css']
})
export class MyServicesComponent implements OnInit {
  servicetypeoffered='';
  servicetyperequested='';  
  serviceSearchInputOffered='';
  serviceSearchInputRequested='';
  myServices:any;  
  serviceTypes:any;
  constructor(private service:ServiceService, private router:Router) { }
  currentUserId;
  myoffered=[];
  myrequested=[];

  navigatePage(page) {
    this.router.navigate([page])
  }
  ngOnInit(): void {
    this.service.fetchservicetypes().subscribe((data:any)=>{
      console.log("data==>", data)
      this.serviceTypes=data.serviceTypes;
    })
    this.service.myservices('').subscribe((data:any)=>{
      console.log("data==>", data)
      this.myServices=data.services;
      this.segregateServices();
    })
    this.currentUserId = JSON.parse(localStorage.getItem('user')).userId;
    console.log("currentUserId==>", this.currentUserId);
  }

  fetchUrls(json){
    return JSON.parse(json);
  }

  markcomplete(serviceId, bookingId){
    this.service.updateBooking({serviceId:serviceId, bookingId:bookingId, status:3}).subscribe((data:any)=>{
      this.service.myservices('').subscribe((data:any)=>{
        console.log("data==>", data)
        this.myServices=data.services;
        this.segregateServices();
      })
    })
  }

  updateServices(type){
    let filters='';
    if(type==1){
      this.myoffered=[];
      if(this.servicetypeoffered){     
        filters+='serviceType='+this.servicetypeoffered
      }
      if(this.serviceSearchInputOffered){
        filters+=filters?'&filter='+this.serviceSearchInputOffered:'filter='+this.serviceSearchInputOffered
      }
    } else {
      this.myrequested=[];
      if(this.servicetyperequested){     
        filters+='serviceType='+this.servicetyperequested
      }
      if(this.serviceSearchInputRequested){
        filters+=filters?'&filter='+this.serviceSearchInputRequested:'filter='+this.serviceSearchInputRequested
      }
    }

    this.service.myservices(filters).subscribe((data:any)=>{
      this.myServices=[];
      this.myServices=data.services;
      if(data.services && data.services.length){
        for(let a of data.services){
          if(a.type==type && type==1){
            this.myoffered.push(a)
          } else if(a.type==type && type==2) {
            this.myrequested.push(a)
          }
        }
      }      
    })
  }

  deleteMyService(userServiceId){
    if(confirm("Are you sure you want to delete this service?")){
      this.service.deleteservice('serviceId='+userServiceId).subscribe((data:any)=>{
        this.service.myservices('').subscribe((data:any)=>{
          console.log("data==>", data)
          this.myServices=data.services;
          this.myoffered=[];
          this.myrequested=[];
          this.segregateServices();
        })
      })
    }
  }

  segregateServices() {
    for(let a of this.myServices){
      if(a.type==1){
        this.myoffered.push(a)
      } else {
        this.myrequested.push(a)
      }
    }
  }
}
