
<div id="worship-and-sermons" class="pane-content">
    <section class="panel-pane pane-views-panes pane-ministry-panel-pane-1 block clearfix">
        <app-login-header [title]='"MY BOOKINGS"'></app-login-header>
        <div class="searchBar clearfix">
            <div style="float:right">
                <!--<input type="text" placeholder="Search" class="searchInput" (change)="updateServices()" [(ngModel)]="serviceSearchInput" />-->
                <select class="searchSelect" (change)="updateServices()" [(ngModel)]="bookingType">
                    <option value="">All Bookings</option>
                    <option value="Booked By">Booked By</option>
                    <option value="Offered By">Offered By</option>
                </select>
            </div>
            <div style="float:left; margin-top:5px;">TOTAL {{myServices?myServices.length:0}} {{myServices && myServices.length==1?'BOOKING' : 'BOOKINGS'}} FOUND</div>    
        </div>        
        <div class="panel-separator"></div>
        <div class="servicesList clearfix">
            <div class="serviceItem" *ngFor="let a of myServices">
                <div class="serviceImage">
                    <img *ngIf="a.booking_id && a.offerer_id!=currentUserId" src="{{a.opi?a.opi:'/assets/images/default-profile.jpg'}}" style="max-height:128px; max-width:128px" />
                    <img *ngIf="a.booking_id && a.requester_id!=currentUserId" src="{{a.rpi?a.rpi:'/assets/images/default-profile.jpg'}}" style="max-height:128px; max-width:128px" />
                </div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600; text-transform: capitalize;">{{a.requester_id==currentUserId?'Offered By':'Booked By'}} {{a.booking_id && a.offerer_id!=currentUserId?a.offererFirstName+' '+a.offererLastName:''}}{{a.booking_id && a.requester_id!=currentUserId?a.requesterFirstName+' '+a.requesterLastName:''}}</h3>
                    <i>{{a.serviceName}}</i><br />
                    {{a.location}}<br />
                    <strong>Service Length:</strong> {{a.serviceLength}}<br />
                    <strong>{{a.type==1? 'Offer': 'Request'}} Id:</strong> {{a.userServiceId}}<br />
                    <strong>Booked on:</strong> {{a.startedAt|date:'medium'}}
                    <div *ngIf="a.endedAt"><strong>Completed on:</strong> {{a.endedAt|date:'medium'}}</div>
                    <div class="divfiles" *ngIf="a.docs"><a *ngFor="let url of fetchUrls(a.docs)" class="files" style="margin-right: 10px;" target="_blank" [href]="url.fullUrl"><img src="../assets/images/file.png" style="max-width:15px; margin-right:3px;" />{{url.name}}</a></div>
                </div>
                
                <div class="serviceCTAs" style="font-family: 'Open Sans Condensed', Arial; font-size:15px; font-weight:bold;" >
                    <div *ngIf="a.booking_id && a.bookingstatus==2 && currentUserId==a.offerer_id" style="color:red; border:1px #f00 solid; padding:5px; float:left; margin-right:5px;">
                        <a *ngIf="a.booking_id && a.bookingstatus==2 && currentUserId==a.offerer_id" href="javascript:void(0)" style="text-decoration: none; color:red" (click)="markcomplete(a.userServiceId, a.booking_id)">Mark Complete</a>
                    </div>
                </div>

            </div>
            <!--<div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs"></div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                </div>
            </div>-->
        </div>
    </section>    
</div>
