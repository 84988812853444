<div id="worship-and-sermons" class="pane-content">
    <div class="panel-pane pane-block pane-block-14 no-title block">        
        <app-login-header [title]='"CHANGE PASSWORD"'></app-login-header>
        <div class="registerPanel clearfix">
            <div class="registerForm">
                <div class="elementMargin">
                    <div *ngIf="success" style="color:green; text-align:center; margin-top:20px">Password changed successfully. Please <a routerLink="/login">Login</a> again. </div>
                    <div style="margin-top:20px;">
                        <div class="textlabel">Password</div>
                        <input type="password" [ngClass]="passwordClass" (keyup)="validateRegister($event)" id="password" [(ngModel)]='user.password' placeholder="Password" />
                    </div>
                    <div class="errorMessage none">Please enter valid email address!</div>
                    <div>
                        <div class="textlabel">Confirm Password</div>
                        <input type="password" [ngClass]="confirmClass" (keyup)="validateRegister($event)" id="confirm" [(ngModel)]='user.confirm' placeholder="Confirm Password" />
                    </div>
                    <div [ngClass]="confirmLabel" class="errorMessage none">Password and confirm password don't match!</div>                    
                    <div style="margin-top: 15px;">
                        <input type="button" [disabled]="success" (click)='register()' (keyup)="validateRegister($event)" class="submitBtn" value="SAVE" style="float: left;" />
                        <!--<div style="float:right; margin-right:12%; margin-top:5px; font-family: 'Open Sans Condensed', Arial; font-weight: 300;">
                            Already have an account? 
                            <a style="color:#EC1C24; font-weight:600; text-decoration:none" href="/login">LOGIN</a>
                    </div>-->                         
                    </div>
                    <div style="clear:both; padding-top:10px; font-family: 'Open Sans Condensed', Arial; font-weight: 300;">
                            
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
