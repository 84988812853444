import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginHeaderComponent } from './login-header/login-header.component';
import { AlwaysAuthGuardService } from './always-auth-guard.service';
import { AdminAuthGuardService } from './admin-auth-guard.service';
import { OfferedServicesComponent, ArraySortPipe } from './offered-services/offered-services.component';
import { RequestedServicesComponent } from './requested-services/requested-services.component';
import { MyServicesComponent } from './my-services/my-services.component';
import { MyBookingsComponent } from './my-bookings/my-bookings.component';
import { OfferServiceComponent } from './offer-service/offer-service.component';
import { RequestServiceComponent } from './request-service/request-service.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { UserParentComponent } from './user-parent/user-parent.component';
import { AdminParentComponent } from './admin-parent/admin-parent.component';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { AdminAddUserComponent } from './adminAddUser/adminAddUser.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { AdminOfferServiceComponent } from './admin-offer-service/admin-offer-service.component';
import { AdminServicesComponent } from './admin-services/admin-services.component';
import { AdminServicesTypesComponent } from './admin-servicestypes/admin-servicestypes.component';
import { AdminAddServiceTypeComponent } from './admin-add-servicetype/admin-add-servicetype.component';
import { AdminCMSComponent } from './admin-cms/admin-cms.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { SaledonationServicesComponent } from './saledonation-services/saledonation-services.component';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    LoginHeaderComponent,
    OfferedServicesComponent,
    RequestedServicesComponent,
    MyServicesComponent,
    OfferServiceComponent,
    RequestServiceComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AdminLoginComponent,
    AdminDashboardComponent,
    UserParentComponent,
    AdminParentComponent,
    AdminHeaderComponent,
    AdminAddUserComponent,
    AdminUsersComponent,
    AdminOfferServiceComponent,
    MyBookingsComponent,
    AdminServicesComponent,
    AdminServicesTypesComponent,
    AdminAddServiceTypeComponent,
    AdminCMSComponent,
    MyProfileComponent,
    ChangePasswordComponent,
    ArraySortPipe,
    SaledonationServicesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    AlwaysAuthGuardService,
    AdminAuthGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
