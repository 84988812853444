import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private httpClient:HttpClient) { }

  postImage(data, isAdmin?){
    let url = environment.baseUrl+'services/fileupload';
    let token = isAdmin?localStorage.getItem('admintoken'):localStorage.getItem('token');
    let headers = {'Authorization': 'Bearer '+token};
    return this.httpClient.post(url, data, { headers: headers})
  } 
  
  myservices(data:any){
    let token = localStorage.getItem('token');
    let url = environment.baseUrl+'services/myservices';    
    let headers = {'Authorization': 'Bearer '+token};
    let opts:any = {headers:headers};
    if(data){
      opts.params=new HttpParams({
        fromString: data
      })
    }
    return this.httpClient.get(url, opts);
  }

  fetchoffered(data:any){
    let token = localStorage.getItem('token');
    let url = environment.baseUrl+'services/fetchoffered';    
    let headers = {'Authorization': 'Bearer '+token};
    let opts:any = {headers:headers};
    if(data){
      opts.params=new HttpParams({
        fromString: data
      })
    }
    console.log("opts", opts)
    return this.httpClient.get(url, opts);
  }

  fetchrequested(data:any){
    let token = localStorage.getItem('token');
    let url = environment.baseUrl+'services/fetchrequested';    
    let headers = {'Authorization': 'Bearer '+token};
    let opts:any = {headers:headers};
    if(data){
      opts.params=new HttpParams({
        fromString: data
      })
    }
    return this.httpClient.get(url, opts);
  }

  fetchservicetypes(isAdmin?){
    let token = localStorage.getItem('token');
    if(isAdmin){
      token = localStorage.getItem('admintoken');
    }
    
    let url = environment.baseUrl+'services/fetchservicetypes';    
    let headers = {'Authorization': 'Bearer '+token};
    return this.httpClient.get(url, {headers:headers});
  }

  createServiceType(data){
    let configUrl = environment.baseUrl+'services/createservicetype';        
    let token = localStorage.getItem('admintoken');
    let headers = {'Authorization': 'Bearer '+token};
    console.log("configUrl==>", configUrl)
    return this.httpClient.post(configUrl, data, {headers:headers});
  }

  updateServiceType(data){
    let token = localStorage.getItem('admintoken');
    let url = environment.baseUrl+'services/updateservicetype';
    let headers = {'Authorization': 'Bearer '+token};
    console.log("configUrl==>", url)
    return this.httpClient.put(url, data, {headers:headers});
  }

  fetchuserdetails(){
    let token = localStorage.getItem('token');
    let url = environment.baseUrl+'users/fetch';    
    let headers = {'Authorization': 'Bearer '+token};
    return this.httpClient.get(url, {headers:headers});
  }

  fetchcms(){
    let url = environment.baseUrl+'cmses/fetch';
    return this.httpClient.get(url);
  }

  updatecms(data){
    let url = environment.baseUrl+'cmses/update';
    let token = localStorage.getItem('admintoken');
    let headers = {'Authorization': 'Bearer '+token};
    console.log("configUrl==>", url)
    return this.httpClient.put(url, data, {headers:headers});
  }

  saveoffer(data){
    let configUrl = environment.baseUrl+'services/offer';
    let token = localStorage.getItem('token');
    if(data.isAdmin){
      token = localStorage.getItem('admintoken');
    }
    let headers = {'Authorization': 'Bearer '+token};
    console.log("configUrl==>", configUrl)
    return this.httpClient.post(configUrl, data, {headers:headers});
  }

  saverequest(data){
    let configUrl = environment.baseUrl+'services/request';        
    let token = localStorage.getItem('token');
    let headers = {'Authorization': 'Bearer '+token};
    console.log("configUrl==>", configUrl)
    return this.httpClient.post(configUrl, data, {headers:headers});
  }

  fetchusers(){
    let token = localStorage.getItem('admintoken');
    let url = environment.baseUrl+'users/fetchall';    
    let headers = {'Authorization': 'Bearer '+token};
    return this.httpClient.get(url, {headers:headers});
  }

  updateActivation(data){
    let token = localStorage.getItem('admintoken');
    let url = environment.baseUrl+'users/update'; 
    let headers = {'Authorization': 'Bearer '+token};
    console.log("configUrl==>", url)
    return this.httpClient.put(url, data, {headers:headers});
  }

  updateProfile(data){
    let token = localStorage.getItem('token');
    let url = environment.baseUrl+'users/update'; 
    let headers = {'Authorization': 'Bearer '+token};
    console.log("configUrl==>", url)
    return this.httpClient.put(url, data, {headers:headers});
  }

  fetchservices(){
    let token = localStorage.getItem('admintoken');
    let url = environment.baseUrl+'services/fetchall';    
    let headers = {'Authorization': 'Bearer '+token};
    return this.httpClient.get(url, {headers:headers});
  }

  fetchservicesadmin(data:any){
    let token = localStorage.getItem('admintoken');
    let url = environment.baseUrl+'services/fetchservicesadmin';    
    let headers = {'Authorization': 'Bearer '+token};
    let opts:any = {headers:headers};
    if(data){
      opts.params=new HttpParams({
        fromString: data
      })
    }
    return this.httpClient.get(url, opts);
  }

  fetchallservicesadmin(){
    let token = localStorage.getItem('admintoken');
    let url = environment.baseUrl+'services/fetchallservicesadmin';    
    let headers = {'Authorization': 'Bearer '+token};
    let opts:any = {headers:headers};
    
    return this.httpClient.get(url, opts);
  }
  
  fetchuseradmin(userId){
    let token = localStorage.getItem('admintoken');
    let url = environment.baseUrl+'users/fetch';    
    let headers = {'Authorization': 'Bearer '+token};
    return this.httpClient.post(url, {userId:userId}, {headers:headers});
  }
  
  updateService(data){
    let token = localStorage.getItem('token');
    let url = environment.baseUrl+'services/update';
    if(data.isAdmin){
      token = localStorage.getItem('admintoken');
    } 
    let headers = {'Authorization': 'Bearer '+token};
    console.log("configUrl==>", url)
    return this.httpClient.put(url, data, {headers:headers});
  }

  bookservice(data){
    let token = localStorage.getItem('token');
    let url = environment.baseUrl+'services/book'; 
    let headers = {'Authorization': 'Bearer '+token};
    return this.httpClient.post(url, data, {headers:headers});
  }

  updateBooking(data){
    let token = localStorage.getItem('token');
    let url = environment.baseUrl+'services/avail'; 
    let headers = {'Authorization': 'Bearer '+token};
    console.log("configUrl==>", url)
    return this.httpClient.put(url, data, {headers:headers});
  }
  
  mybookings(){
    let token = localStorage.getItem('token');
    let url = environment.baseUrl+'services/fetchbookings';    
    let headers = {'Authorization': 'Bearer '+token};
    let opts:any = {headers:headers};
    return this.httpClient.get(url, opts);
  }
  deleteserviceadmin(data:any){
    let token = localStorage.getItem('admintoken');
    let url = environment.baseUrl+'services/deleteservice';    
    let headers = {'Authorization': 'Bearer '+token};
    let opts:any = {headers:headers};
    if(data){
      opts.params=new HttpParams({
        fromString: data
      })
    }
    return this.httpClient.delete(url, opts);
  }

  deleteuseradmin(data:any){
    let token = localStorage.getItem('admintoken');
    let url = environment.baseUrl+'users/delete';    
    let headers = {'Authorization': 'Bearer '+token};
    let opts:any = {headers:headers};
    if(data){
      opts.params=new HttpParams({
        fromString: data
      })
    }
    return this.httpClient.delete(url, opts);
  }

  deleteservice(data:any){
    let token = localStorage.getItem('token');
    let url = environment.baseUrl+'services/deleteservice';    
    let headers = {'Authorization': 'Bearer '+token};
    let opts:any = {headers:headers};
    if(data){
      opts.params=new HttpParams({
        fromString: data
      })
    }
    return this.httpClient.delete(url, opts);
  }
}
