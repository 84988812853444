import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { ServiceService } from '../services/service.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  cmsContent;
  constructor(private loginService: LoginService, private router: Router, private service:ServiceService) {
    if(localStorage.getItem('token')){
      this.loginService.validateToken(localStorage.getItem('token')).subscribe((token:any) => {
        if(token && token.status=='success'){
          this.router.navigate(['dashboard'])
        } else{
          localStorage.removeItem('token')
        }
      })
    }
    this.service.fetchcms().subscribe((data:any)=>{
      console.log("data==>", data)
      if(data.cms.cmsId){
        //this.myService=a;
        this.cmsContent = data.cms.cmsContent;
      }  
    })
  }

  ngOnInit(): void {
  }

}
