import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';

@Component({
  selector: 'app-requested-services',
  templateUrl: './requested-services.component.html',
  styleUrls: ['./requested-services.component.css']
})
export class RequestedServicesComponent implements OnInit {
  servicetype='';
  serviceSearchInput='';
  requestedServices:any;  
  serviceTypes:any;
  toastStatusClass:any={
    shown:false
  }
  spinnerStatusClass:any={
    shown:false
  }
  servicestatus=1;
  
  userId:String;
  constructor(private service:ServiceService) { }

  ngOnInit(): void {
    this.service.fetchrequested('status=1').subscribe((data:any)=>{
      console.log("data==>", data)
      this.requestedServices=data.services;
    })
    this.service.fetchservicetypes().subscribe((data:any)=>{
      console.log("data==>", data)
      this.serviceTypes=data.serviceTypes;
    })
    this.userId = JSON.parse(localStorage.getItem('user')).userId;
  }

  fetchUrls(json){
    console.log(JSON.parse(json));
    return JSON.parse(json);
  }

  updateServices(){
    console.log("this.servicetype==>", this.servicetype)
    let filters='';
    if(this.servicetype){
      filters+='serviceType='+this.servicetype
    }
    if(this.servicestatus){
      filters+=filters?'&status='+this.servicestatus:'status='+this.servicestatus
    }
    if(this.serviceSearchInput){
      filters+=filters?'&filter='+this.serviceSearchInput:'filter='+this.serviceSearchInput
    }
    this.service.fetchrequested(filters).subscribe((data:any)=>{
      console.log("data==>", data)
      this.requestedServices=data.services;
    })
  }

  bookservice(service){
    this.spinnerStatusClass.shown=true;
    let serviceBooking={
      userId:0,
      type:0,
      commerceValue:0,
      status:0,
      serviceId:0
    };
    serviceBooking.userId = service.userId;
    serviceBooking.type = service.type;
    console.log("service==>",service);
    serviceBooking.commerceValue = service.price;
    serviceBooking.status = 2;
    serviceBooking.serviceId = service.userServiceId;


    this.service.bookservice(serviceBooking).subscribe((data:any)=>{
      this.service.fetchrequested('').subscribe((data:any)=>{
        this.requestedServices=data.services;
        this.spinnerStatusClass.shown=false;
        this.toastStatusClass.shown=true;
      })
    })
  }
  hideToast(){
    this.toastStatusClass.shown=false;
  }

}
