import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ServiceService } from '../services/service.service';
export type SortOrder = 'asc' | 'desc';
@Pipe({
  name: "sort"
})
export class ArraySortPipe  implements PipeTransform {
  transform(value: any[], sortOrder: SortOrder | string = 'asc', sortKey?: string): any {
    sortOrder = sortOrder && (sortOrder.toLowerCase() as any);

    if (!value || (sortOrder !== 'asc' && sortOrder !== 'desc')) return value;

    let numberArray = [];
    let stringArray = [];

    if (!sortKey) {
      numberArray = value.filter(item => typeof item === 'number').sort();
      stringArray = value.filter(item => typeof item === 'string').sort();
    } else {
      numberArray = value.filter(item => typeof item[sortKey] === 'number').sort((a, b) => a[sortKey] - b[sortKey]);
      stringArray = value
        .filter(item => typeof item[sortKey] === 'string')
        .sort((a, b) => {
          if (a[sortKey] < b[sortKey]) return -1;
          else if (a[sortKey] > b[sortKey]) return 1;
          else return 0;
        });
    }
    const sorted = numberArray.concat(stringArray);
    return sortOrder === 'asc' ? sorted : sorted.reverse();
  }
}

@Component({
  selector: 'app-offered-services',
  templateUrl: './offered-services.component.html',
  styleUrls: ['./offered-services.component.css']
})
export class OfferedServicesComponent implements OnInit {
  servicetype='';
  serviceSearchInput='';
  offeredServices:any;
  offeredServicesOther:any;
  serviceTypes:any; 
  offerLength=0;
  servicestatus=1;
  spinnerStatusClass:any={
    shown:false
  } 
  toastStatusClass:any={
    shown:false
  }

  offeredServicesCount=0;

  userId:String;
  constructor(private service:ServiceService) { }

  ngOnInit(): void {
    this.service.fetchoffered('status=1').subscribe((data:any)=>{
      console.log("data==>", data)
      this.offeredServices=[];
      this.offeredServicesOther=[];
      for(let a of data.services){
        if(a.serviceName.toLowerCase()!='FOR SALE/DONATION'.toLowerCase()){
          if(a.serviceName.toLowerCase()=='other' || a.serviceName.toLowerCase()=='others'){
            this.offeredServicesOther.push(a);
            this.offerLength++;
          }else{
            this.offeredServices.push(a);
            this.offerLength++;
          }
        }        
      }
    })
    this.service.fetchservicetypes().subscribe((data:any)=>{
      console.log("data==>", data)
      this.serviceTypes=data.serviceTypes;
    })
    this.userId = JSON.parse(localStorage.getItem('user')).userId;
  }
  
  fetchUrls(json){
    console.log(JSON.parse(json));
    return JSON.parse(json);
  }

  updateServices(){
    let filters='';
    if(this.servicetype){
      filters+='serviceType='+this.servicetype
    }
    if(this.servicestatus){
      filters+=filters?'&status='+this.servicestatus:'status='+this.servicestatus
    }
    if(this.serviceSearchInput){
      filters+=filters?'&filter='+this.serviceSearchInput:'filter='+this.serviceSearchInput
    }
    this.service.fetchoffered(filters).subscribe((data:any)=>{
      console.log("data==>", data)
      this.offeredServices=[];
      this.offeredServicesOther=[];
      this.offerLength = 0;
      for(let a of data.services){
        if(a.serviceName.toLowerCase()!='FOR SALE/DONATION'.toLowerCase() && a.serviceName.toLowerCase()!='other' && a.serviceName.toLowerCase()!='others'){
          this.offeredServices.push(a);
          this.offerLength++;
        } else if(a.serviceName.toLowerCase()=='other' || a.serviceName.toLowerCase()=='others'){
          this.offeredServicesOther.push(a);
          this.offerLength++;
        }        
      }
    })
  }

  bookservice(service){
    console.log("spinnerStatusClass==>", this.spinnerStatusClass)
    this.spinnerStatusClass.shown=true;
    console.log("spinnerStatusClass==>", this.spinnerStatusClass)
    let serviceBooking={
      userId:0,
      type:0,
      commerceValue:0,
      status:0,
      serviceId:0
    };
    serviceBooking.userId = service.userId;
    serviceBooking.type = service.type;
    console.log("service==>",service);
    serviceBooking.commerceValue = service.price;
    serviceBooking.status = 2;
    serviceBooking.serviceId = service.userServiceId;


    this.service.bookservice(serviceBooking).subscribe((data:any)=>{
      this.service.fetchoffered('').subscribe((data:any)=>{
        this.spinnerStatusClass.shown=false;
        this.offeredServices=data.services;
        this.toastStatusClass.shown=true;
      })
    })
  }

  hideToast(){
    this.toastStatusClass.shown=false;
  }
  
}


