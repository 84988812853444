import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { RegisterService } from '../register.service';
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from './../../environments/environment';
import { ServiceService } from '../services/service.service';

@Component({
  selector: 'app-adminadduser',
  templateUrl: './adminAddUser.component.html',
  styleUrls: ['./adminAddUser.component.css']
})
export class AdminAddUserComponent implements OnInit {
  withError = {textBox:true, error:true};
  withoutError = {textBox:true, error:false};
  fsClass = this.withoutError;
  lsClass = this.withoutError;
  emailClass = this.withoutError;
  phoneClass = this.withoutError;
  passwordClass = this.withoutError;
  emailContentClass = this.withoutError;
  confirmClass = this.withoutError;
  profileClass = this.withoutError;
  emailLabel = {errorMessage:true, none:true};
  confirmLabel = {errorMessage:true, none:true};
  profileLabel = {errorMessage:true, none:true};
  apiError;  
  user={
    firstName:'',
    lastName:'',
    email:'',
    phone:'',
    password:'',
    confirm:'',
    profileImage:'',
    isAdmin:true,
    emailContent:"Hello {{firstName}}, \r\nPlease use following url to login to CLM: http://clm.cedarlane.org/login \r\nUse this email and following is your password to login: {{password}}\r\n \r\nPlease also check your profile and update your picture",
    setPass:false
  }
  file='';
  header="Edit User Details";
  isEdit=true;
  userId='';
  constructor(private loginService: LoginService, private route:ActivatedRoute, private registerService: RegisterService, private router: Router, private service:ServiceService) {
    
  }

  ngOnInit(): void {
    if(this.router.url=='/admin/users/adduser'){
      this.header = 'Add User';
      this.isEdit=false;
    } else {
      this.userId=this.route.snapshot.paramMap.get('id') 
      this.service.fetchuseradmin(this.userId).subscribe((user:any)=>{
        this.user = user.user;
      })
    }

  }

  checkVal(event){
    this.user.setPass = event.currentTarget.checked?true:false;
  }

  goback(){
    this.router.navigate(['/admin/users'])
  }
  register(){

    if(!this.user.firstName){
      this.fsClass=this.withError;
      return;
    }else{
      this.fsClass=this.withoutError;
    }
    if(!this.user.lastName){
      this.lsClass=this.withError;
      return;
    }else{
      this.lsClass=this.withoutError;
    }
    /*if(!this.user.profileImage){        
      this.profileClass=this.withError;
      this.profileLabel.none=false;
      return;
    }else{      
      this.profileClass=this.withoutError;
      this.profileLabel.none=true;        
    }*/
    if(!this.user.email){        
      this.emailClass=this.withError;
      this.emailLabel.none=true;
      return;
    }else{
      if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email)){
        this.emailClass=this.withError;
        this.emailLabel.none=false;
        return;
      }else{
        this.emailClass=this.withoutError;
        this.emailLabel.none=true;
      }        
    }
    if(!this.user.phone){
      this.phoneClass=this.withError;
      return;
    }else{
      this.phoneClass=this.withoutError;
    }
    
    if(!this.isEdit){
      if(this.user.setPass){
        if(!this.user.password){
          this.passwordClass = this.withError;
          return;
        }else{
          this.passwordClass = this.withoutError;
        }
        if(!this.user.emailContent){
          this.emailContentClass = this.withError;
          return;
        } else {
          this.emailContentClass = this.withoutError;
        }
      }
    }
    
    this.user.email = this.user.email.toLowerCase();
    if(this.isEdit){
      delete this.user.isAdmin;
      this.service.updateActivation(this.user).subscribe((data:any)=>{
        if(data && data.status=='success'){
          console.log("data==>", data)
          this.apiError = '';
          //localStorage.setItem('token', data.token);
          this.router.navigate(['admin/users'])
        } else {
          this.apiError = data.message;
          if(this.apiError.indexOf('Duplicate entry')!=-1){
            this.apiError = 'Email already exists!'  
          }        
        }
      })
    } else {
      if(this.user.setPass){
        this.user.emailContent = this.user.emailContent.replace('{{firstName}}', this.user.firstName)
        this.user.emailContent = this.user.emailContent.replace('{{password}}', this.user.password)
      } else {
        this.user.password = '123456'
      }
      this.registerService.register(this.user).subscribe((data:any) => {
        if(data && data.status=='success'){
          console.log("data==>", data)
          this.apiError = '';
          //localStorage.setItem('token', data.token);
          this.router.navigate(['admin/users'])
        } else {
          this.apiError = data.message;
          if(this.apiError.indexOf('Duplicate entry')!=-1){
            this.apiError = 'Email already exists!'  
          }        
        }
      })
    }    
  }

  validateRegister(event){
    console.log("eve")
    if(event.target.id=='fs'){
      if(!this.user.firstName){
        this.fsClass=this.withError;
      }else{
        this.fsClass=this.withoutError;
      }
    } else if(event.target.id=='ls') {
      if(!this.user.lastName){
        this.lsClass=this.withError;
      }else{
        this.lsClass=this.withoutError;
      }
    } else if(event.target.id=='email'){
      if(!this.user.email){
        this.emailClass=this.withError;
        this.emailLabel.none=true;
      }else{
        if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email)){
          this.emailClass=this.withError;
          this.emailLabel.none=false;
        }else{
          this.emailClass=this.withoutError;
          this.emailLabel.none=true;
        }        
      }
    } else if(event.target.id=='phone'){
      if(!this.user.phone){
        this.phoneClass=this.withError;
      }else{
        this.phoneClass=this.withoutError;
      }
    }
    if(!this.isEdit){
      if(this.user.setPass){
        if(!this.user.password){
          this.passwordClass = this.withError;
        }else{
          this.passwordClass = this.withoutError;
        }
        if(!this.user.emailContent){
          this.emailContentClass = this.withError;
        } else {
          this.emailContentClass = this.withoutError;
        }
      }
    } 
  }

  fileUploaded(event){
    let data = {};
    let files = event.srcElement.files;
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(i.toString(), files[i], files[i].name);
    }
    formData.append("data", JSON.stringify(data));
    this.registerService.postImage(formData).subscribe((fileUpload:any)=>{
      this.user.profileImage = environment.baseUrl+fileUpload.data;
      this.file = fileUpload.name;
    });
  }

}
