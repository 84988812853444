import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { RegisterService } from '../register.service';
import { Router } from "@angular/router";
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  withError = {textBox:true, error:true};
  withoutError = {textBox:true, error:false};
  fsClass = this.withoutError;
  lsClass = this.withoutError;
  emailClass = this.withoutError;
  phoneClass = this.withoutError;
  passwordClass = this.withoutError;
  confirmClass = this.withoutError;
  profileClass = this.withoutError;
  emailLabel = {errorMessage:true, none:true};
  confirmLabel = {errorMessage:true, none:true};
  profileLabel = {errorMessage:true, none:true};
  user={
    firstName:'',
    lastName:'',
    email:'',
    phone:'',
    password:'',
    confirm:'',
    profileImage:''
  }
  file='';
  apiError='';
  constructor(private loginService: LoginService, private registerService: RegisterService, private router: Router) {
    if(localStorage.getItem('token')){
      this.loginService.validateToken(localStorage.getItem('token')).subscribe((token:any) => {
        console.log("token==>", token.status)
        if(token && token.status=='success'){
          this.router.navigate(['dashboard'])
        } else{
          localStorage.removeItem('token')
        }
      })
    }
  }

  ngOnInit(): void {
  }
  register(){

    if(!this.user.firstName){
      this.fsClass=this.withError;
      return;
    }else{
      this.fsClass=this.withoutError;
    }
    if(!this.user.lastName){
      this.lsClass=this.withError;
      return;
    }else{
      this.lsClass=this.withoutError;
    }
    /*if(!this.user.profileImage){        
      this.profileClass=this.withError;
      this.profileLabel.none=false;
      return;
    }else{      
      this.profileClass=this.withoutError;
      this.profileLabel.none=true;        
    }*/
    if(!this.user.email){        
      this.emailClass=this.withError;
      this.emailLabel.none=true;
      return;
    }else{
      if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email)){
        this.emailClass=this.withError;
        this.emailLabel.none=false;
        return;
      }else{
        this.emailClass=this.withoutError;
        this.emailLabel.none=true;
      }        
    }
    if(!this.user.phone){
      this.phoneClass=this.withError;
      return;
    }else{
      this.phoneClass=this.withoutError;
    }
    if(!this.user.password){
      this.passwordClass=this.withError;
      return;
    }else{
      this.passwordClass=this.withoutError;
    }
    if(!this.user.confirm){
      this.confirmClass=this.withError;
      this.confirmLabel.none = true;
      return;
    }else{
      if(this.user.password!=this.user.confirm){
        this.confirmClass=this.withError;
        this.confirmLabel.none = false;
        return;
      } else{
        this.confirmClass=this.withoutError;
        this.confirmLabel.none = true;
      }
    }
    
    this.user.email = this.user.email.toLowerCase();

    this.registerService.register(this.user).subscribe((data:any) => {
      if(data && data.status=='success'){
        console.log("data==>", data)
        localStorage.setItem('token', data.token);
        this.router.navigate(['dashboard'])
      } else { 
        this.apiError = data.message;
        if(this.apiError.indexOf('Duplicate entry')!=-1){
          this.apiError = 'Email already exists!'  
          this.emailClass=this.withError;
        }
        setTimeout(()=>{this.apiError=''}, 3000)
      }
    })
  }

  validateRegister(event){
    console.log("eve")
    if(event.keyCode!=13){
      if(event.target.id=='fs'){
        if(!this.user.firstName){
          this.fsClass=this.withError;
        }else{
          this.fsClass=this.withoutError;
        }
      } else if(event.target.id=='ls') {
        if(!this.user.lastName){
          this.lsClass=this.withError;
        }else{
          this.lsClass=this.withoutError;
        }
      } else if(event.target.id=='email'){
        if(!this.user.email){
          this.emailClass=this.withError;
          this.emailLabel.none=true;
        }else{
          if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email)){
            this.emailClass=this.withError;
            this.emailLabel.none=false;
          }else{
            this.emailClass=this.withoutError;
            this.emailLabel.none=true;
          }        
        }
      } else if(event.target.id=='phone'){
        if(!this.user.phone){
          this.phoneClass=this.withError;
        }else{
          this.phoneClass=this.withoutError;
        }
      } else if(event.target.id=='password'){
        if(!this.user.password){
          this.passwordClass=this.withError;
        }else{
          this.passwordClass=this.withoutError;
        }
      } else if(event.target.id=='confirm'){
        if(!this.user.confirm){
          this.confirmClass=this.withError;
          this.confirmLabel.none = true;
        }else{
          if(this.user.password!=this.user.confirm){
            this.confirmClass=this.withError;
            this.confirmLabel.none = false;
          } else{
            this.confirmClass=this.withoutError;
            this.confirmLabel.none = true;
          }
        }
      }
    } else {
      this.register();
    }
    
  }

  fileUploaded(event){
    let data = {};
    let files = event.srcElement.files;
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(i.toString(), files[i], files[i].name);
    }
    formData.append("data", JSON.stringify(data));
    this.registerService.postImage(formData).subscribe((fileUpload:any)=>{
      this.user.profileImage = environment.baseUrl+fileUpload.data;
      this.file = fileUpload.name;
    });
  }

}
