<div id="worship-and-sermons" class="pane-content">
    <div class="panel-pane pane-block pane-block-14 no-title block">        
        <div style="margin-bottom:55px; width:100%">
            <h1 class="node-title" style="text-align: center; font-family:'Open Sans Condensed', Arial"> SIGN UP </h1>
        </div>
        <div class="registerPanel clearfix">
            <div class="registerImage"></div>
            <div class="registerForm">
                <div class="elementMargin">
                    <div *ngIf="apiError" style="text-align:center; color:red; margin-top:5px;">{{apiError}}</div>
                    <div class="firstDiv">
                        <div class="textlabel">First Name</div>
                        <input type="text" [ngClass]="fsClass" id="fs" (keyup)="validateRegister($event)" [(ngModel)]='user.firstName' placeholder="First Name" />
                    </div>
                    <div class="errorMessage none">Please enter valid email address!</div>
                    <div>
                        <div class="textlabel">Last Name</div>
                        <input type="text" [ngClass]="lsClass" id="ls" (keyup)="validateRegister($event)" [(ngModel)]='user.lastName' placeholder="Last Name" />
                    </div>
                    <div class="errorMessage none">Please enter valid email address!</div>
                    <div>
                        <div class="textlabel">Upload Profile Image</div>
                        <input type="file" class="file" accept="application/pdf, image/*" style="clear:right; opacity:0; z-index: 100;" (change)="fileUploaded($event)" placeholder="Upload Document" />
                        <div style="margin-top:-38px; z-index:-1;">
                            <input type="text" [ngClass]="profileClass" style="width:50%;" disabled value="{{file}}" />
                            <a class="itunes-btn" href="javascript:void(0)" style="margin-left:10px; padding:4.5px 0px; background: #E8E7E7; width:30%; text-align: center; vertical-align: sub;">BROWSE</a>
                        </div>
                    </div>
                    <div [ngClass]="profileLabel">Please upload profile image!</div>
                    <div>
                        <div class="textlabel">Email Address</div>
                        <input type="email" [ngClass]="emailClass" id="email" (keyup)="validateRegister($event)" [(ngModel)]='user.email' placeholder="Email Address" />
                    </div>
                    <div [ngClass]="emailLabel">Please enter valid email address!</div>
                    <div>
                        <div class="textlabel">Phone</div>
                        <input type="text" [ngClass]="phoneClass" mask="(000)-000-0000" id="phone" (keyup)="validateRegister($event)" [(ngModel)]='user.phone' placeholder="Phone" />
                    </div>
                    <div class="errorMessage none">Please enter valid email address!</div>
                    <div>
                        <div class="textlabel">Password</div>
                        <input type="password" [ngClass]="passwordClass" (keyup)="validateRegister($event)" id="password" [(ngModel)]='user.password' placeholder="Password" />
                    </div>
                    <div class="errorMessage none">Please enter valid email address!</div>
                    <div>
                        <div class="textlabel">Confirm Password</div>
                        <input type="password" [ngClass]="confirmClass" (keyup)="validateRegister($event)" id="confirm" [(ngModel)]='user.confirm' placeholder="Confirm Password" />
                    </div>
                    <div [ngClass]="confirmLabel" class="errorMessage none">Password and confirm password don't match!</div>                    
                    <div style="margin-top: 15px;">
                        <input type="button" (click)='register()' (keyup)="validateRegister($event)" class="submitBtn" value="SIGN UP" style="float: left;" />
                        <!--<div style="float:right; margin-right:12%; margin-top:5px; font-family: 'Open Sans Condensed', Arial; font-weight: 300;">
                            Already have an account? 
                            <a style="color:#EC1C24; font-weight:600; text-decoration:none" href="/login">LOGIN</a>
                    </div>-->                         
                    </div>
                    <div style="clear:both; padding-top:10px; font-family: 'Open Sans Condensed', Arial; font-weight: 300;">
                            Already have an account? 
                            <a style="color:#EC1C24; font-weight:600; text-decoration:none" [routerLink]="['/login']">LOGIN</a>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
