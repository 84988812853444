import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from './../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) { }

  register(data){
    let configUrl = environment.baseUrl+'users/register';
    console.log("configUrl==>", configUrl)
    return this.http.post(configUrl, data);
  }
  postImage(data){
    let url = environment.baseUrl+'users/fileupload';
    return this.http.post(url, data)
  } 
}
