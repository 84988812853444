import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-admin-parent',
  templateUrl: './admin-parent.component.html',
  styleUrls: ['./bootstrap.css']
})
export class AdminParentComponent implements OnInit {

  constructor(private titleService: Title) { 
    this.titleService.setTitle( 'CedarLane Marketplace - Admin' );
  }

  ngOnInit(): void {
  }

  public setTitle( newTitle: string) {
    
  }

}
