import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.css']
})
export class LoginHeaderComponent implements OnInit {

  @Input() title;

  @Input() user;

  isSearchBar = false;

  pages=['OFFERED SERVICES', 'REQUESTED SERVICES', 'MY SERVICES', 'SALE/DONATION SERVICES']

  constructor(private router: Router) {
    
  }

  logout(){
    localStorage.removeItem('token');
    this.router.navigate(['login'])
  }

  ngOnInit(): void { 
    if(this.pages.indexOf(this.title)!=-1){
      this.isSearchBar = true;
    }
  }

}
