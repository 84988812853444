<div id="worship-and-sermons" class="pane-content">
    <div class="panel-pane pane-block pane-block-14 no-title block">        
        <div style="margin-bottom:55px; width:100%">
            <h1 class="node-title" style="text-align: center; font-family:'Open Sans Condensed', Arial"> ADMIN LOGIN </h1>
        </div>
        <div class="loginPanel clearfix">
            <div class="loginImage"></div>
            <div class="loginForm">                
                <div class="elementMargin">
                    <div class="firstDiv">
                        <div class="errorMessage" *ngIf='apiError' style="text-align:center; text-transform: capitalize;">{{apiError}}</div>
                        <div class="textlabel">Email Address</div>
                        <input type="email" [ngClass]="emailClass" (keyup)="validateLogin($event)" id="email" [(ngModel)]='user.email' placeholder="Enter Email Address" />
                    </div>
                    <div [ngClass]="emailLabel">Please enter valid email!</div>
                    <div>
                        <div class="textlabel">Password</div>
                        <input type="password" [ngClass]="passwordClass" (keyup)="validateLogin($event)" id="password" [(ngModel)]='user.password' placeholder="Enter Password" />
                    </div>
                    <div [ngClass]="passwordLabel">Please enter password!</div>
                    <div class=" checkboxDiv">
                        <input id="checkbox-2" class="checkbox-custom" name="checkbox-2" type="checkbox"> <label class="checkbox-custom-label">Remember Me </label>
                    </div>

                    <div style="margin-top: 23px;">
                        <input type="button" (click)="login()" class="submitBtn" value="Login" style="float:right; margin-right:12%;" />                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
