import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AlwaysAuthGuardService } from './always-auth-guard.service';
import { AdminAuthGuardService } from './admin-auth-guard.service';
import { OfferedServicesComponent } from './offered-services/offered-services.component';
import { RequestedServicesComponent } from './requested-services/requested-services.component';
import { MyServicesComponent } from './my-services/my-services.component';
import { MyBookingsComponent } from './my-bookings/my-bookings.component';
import { OfferServiceComponent } from './offer-service/offer-service.component';
import { RequestServiceComponent } from './request-service/request-service.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { UserParentComponent } from './user-parent/user-parent.component';
import { AdminParentComponent } from './admin-parent/admin-parent.component';
import { AdminAddUserComponent } from './adminAddUser/adminAddUser.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { AdminOfferServiceComponent } from './admin-offer-service/admin-offer-service.component';
import { AdminServicesComponent } from './admin-services/admin-services.component';
import { AdminServicesTypesComponent } from './admin-servicestypes/admin-servicestypes.component';
import { AdminAddServiceTypeComponent } from './admin-add-servicetype/admin-add-servicetype.component';
import { AdminCMSComponent } from './admin-cms/admin-cms.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SaledonationServicesComponent } from './saledonation-services/saledonation-services.component';

const routes: Routes = [
  { path: '', component: UserParentComponent,
    children:[
      { path: '', component: HomeComponent},
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AlwaysAuthGuardService] }, //
      { path: 'offered', component: OfferedServicesComponent, canActivate: [AlwaysAuthGuardService]  }, //, canActivate: [AlwaysAuthGuardService]
      { path: 'requested', component: RequestedServicesComponent, canActivate: [AlwaysAuthGuardService]  },
      { path: 'my-services', component: MyServicesComponent, canActivate: [AlwaysAuthGuardService]  },      
      { path: 'my-bookings', component: MyBookingsComponent, canActivate: [AlwaysAuthGuardService]  },
      { path: 'offer', component: OfferServiceComponent, canActivate: [AlwaysAuthGuardService]  },
      { path: 'offer/:id', component: OfferServiceComponent, canActivate: [AlwaysAuthGuardService]  },
      { path: 'request', component: RequestServiceComponent, canActivate: [AlwaysAuthGuardService]  },
      { path: 'request/:id', component: RequestServiceComponent, canActivate: [AlwaysAuthGuardService]  },
      { path: 'resetpassword/:userid', component:ResetPasswordComponent },
      { path: 'forgotpassword', component:ForgotPasswordComponent },
      { path: 'my-profile', component: MyProfileComponent, canActivate: [AlwaysAuthGuardService] },
      { path: 'change-password', component: ChangePasswordComponent, canActivate: [AlwaysAuthGuardService] },
      { path: 'sale-donation', component: SaledonationServicesComponent, canActivate: [AlwaysAuthGuardService] }
    ] 
  },
  { path: 'admin', component: AdminParentComponent,
    children:[
      { path: '', component: AdminDashboardComponent, canActivate: [AdminAuthGuardService]},
      { path: 'users', component: AdminUsersComponent, canActivate: [AdminAuthGuardService]},
      { path: 'users/adduser', component: AdminAddUserComponent, canActivate: [AdminAuthGuardService]},
      { path: 'users/edituser/:id', component: AdminAddUserComponent, canActivate: [AdminAuthGuardService]},
      { path: 'services/offerservice/:id', component: AdminOfferServiceComponent, canActivate: [AdminAuthGuardService]},
      { path: 'services/:id', component: AdminServicesComponent, canActivate: [AdminAuthGuardService]},
      { path: 'services', component: AdminServicesComponent, canActivate: [AdminAuthGuardService]},
      { path: 'services/editservice/:id/:serviceId', component: AdminOfferServiceComponent, canActivate: [AdminAuthGuardService]},
      { path: 'services/editservice/:serviceId', component: AdminOfferServiceComponent, canActivate: [AdminAuthGuardService]},
      { path: 'servicecategories', component: AdminServicesTypesComponent, canActivate: [AdminAuthGuardService]},
      { path: 'servicecategories/add', component: AdminAddServiceTypeComponent, canActivate: [AdminAuthGuardService]},
      { path: 'servicecategories/edit/:id', component: AdminAddServiceTypeComponent, canActivate: [AdminAuthGuardService]}, 
      { path: 'cms', component: AdminCMSComponent, canActivate:[AdminAuthGuardService]}

    ] 
  },
  { path: 'admin/login', component: AdminLoginComponent },
  { path: '**', redirectTo:'' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
