import { Injectable } from '@angular/core';
import { CanActivate, Router } from "@angular/router";
import { Observable, throwError } from 'rxjs';
import { environment } from './../environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AlwaysAuthGuardService implements CanActivate {

  constructor(private router:Router, private http:HttpClient) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(res => {
        let token = localStorage.getItem('token');
        if(!token){
          this.router.navigate(['/login']);
          res(false);
          return;
        }
        this.validateToken(token).subscribe(
            (data:any) => {
              console.log("data==>", data)
                if (data.status == 'success') {
                    res(true);
                } else {
                    this.router.navigate(['/login']);
                    res(false);
                }
            }
        );
    });
  }


  validateToken(token){
    let configUrl = environment.baseUrl+'users/validatetoken';
    let headers = {'Authorization': 'Bearer '+token};
    return this.http.get(configUrl, {headers:headers});
  }
}
