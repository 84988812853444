import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ServiceService } from '../services/service.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  requestedServices:any;
  offeredServices=[];
  saledonation:any;
  userDetails:any;
  constructor(private router:Router, private service:ServiceService) { }

  ngOnInit(): void {
    this.service.fetchrequested('').subscribe((data:any)=>{
      this.requestedServices=data.services;
    })
    this.service.fetchoffered('').subscribe((data:any)=>{
      for(let a of data.services){
        if(a.serviceType!=42){
          this.offeredServices.push(a);
        }        
      }
    })
    this.service.fetchoffered('serviceType=42').subscribe((data:any)=>{
      this.saledonation=data.services;
    })
    this.service.fetchuserdetails().subscribe((data:any)=>{
      this.userDetails=data.user; 
      localStorage.setItem('user', JSON.stringify(this.userDetails));
    })
  }
  
  navigatePage(page) {
    this.router.navigate([page])
  }

}
