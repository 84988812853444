import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['../admin-parent/bootstrap.css', './admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {
  userRouteClass={active:false};
  homeRoute={active:true};
  serviceCategoryRouteClass={active:false};
  servicesRouteClass={active:false};
  cmsRouteClass={active:false};
  currentUrl;

  constructor(private router:Router, private activatedRoute:ActivatedRoute) {
    router.events.subscribe((event) => {
      // see also 
      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        this.currentUrl = event.url
        if(this.currentUrl=='/admin/users' || this.currentUrl=='/admin/users/adduser' || this.currentUrl.indexOf('/admin/users/edituser')!=-1){
          this.userRouteClass.active=true;
          this.homeRoute.active=false;
          this.serviceCategoryRouteClass.active = false;
          this.servicesRouteClass.active = false;
          this.cmsRouteClass.active = false;
        } else if (this.currentUrl=='/admin'){
          this.userRouteClass.active=false;
          this.homeRoute.active=true;
          this.serviceCategoryRouteClass.active = false;
          this.servicesRouteClass.active = false;
          this.cmsRouteClass.active = false;
        } else if (this.currentUrl=='/admin/cms'){
          this.userRouteClass.active=false;
          this.homeRoute.active=false;
          this.serviceCategoryRouteClass.active = false;
          this.servicesRouteClass.active = false;
          this.cmsRouteClass.active = true;
        } else if (this.currentUrl =='/admin/servicecategories' || this.currentUrl=='/admin/servicecategories/add' || this.currentUrl.indexOf('/admin/servicecategories/edit')!=-1){
          this.userRouteClass.active=false;
          this.homeRoute.active=false;
          this.serviceCategoryRouteClass.active = true;
          this.servicesRouteClass.active = false;
          this.cmsRouteClass.active = false;
        }else if (this.currentUrl =='/admin/services'){
          this.userRouteClass.active=false;
          this.homeRoute.active=false;
          this.serviceCategoryRouteClass.active = false;
          this.servicesRouteClass.active = true;
          this.cmsRouteClass.active = false;
        }
      }


    });
  }

  ngOnInit(): void {
    
  }

  logout(){
    localStorage.removeItem('admintoken');
    this.router.navigate(['admin/login'])
  }

}
