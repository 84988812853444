<div id="worship-and-sermons" class="pane-content">
    <section class="panel-pane pane-views-panes pane-ministry-panel-pane-1 block clearfix">
        <app-login-header [title]='"MY OFFERS/REQUESTS"'></app-login-header>
        <div style="float:right;">
            <div class="serviceSmall" style="margin-right:20px;" (click)="navigatePage('offer')">
                <div style="text-align: center;">OFFER A SERVICE</div>
            </div>
            <div class="serviceSmall" (click)="navigatePage('request')">
                <div style="text-align: center;">REQUEST A SERVICE</div>
            </div>
        </div>
        <br />
        <br />
        <h3>SERVICES OFFERED BY ME</h3> <br />
        <div class="Offered">
            <div class="searchBar clearfix">
                <div style="float:right">
                    <input type="text" placeholder="Search" class="searchInput" (change)="updateServices(1)" [(ngModel)]="serviceSearchInputOffered" />
                    <select class="searchSelect" (change)="updateServices(1)" [(ngModel)]="servicetypeoffered">
                        <option value="">All Service Types</option>
                        <option *ngFor="let a of serviceTypes" value="{{a.serviceId}}">{{a.serviceName}}</option>
                    </select>
                </div>
                <div style="float:left; margin-top:5px;">TOTAL {{myoffered?myoffered.length:0}} OFFERED {{myoffered && myoffered.length==1?'SERVICE' : 'SERVICES'}} FOUND</div>    
            </div>        
            <div class="panel-separator"></div>
            <div class="servicesList clearfix">
                <div class="serviceItem" *ngFor="let a of myoffered">
                    <div class="serviceImage">
                        <img src="{{a.profileImage?a.profileImage:'/assets/images/default-profile.jpg'}}" style="max-height:128px; max-width:128px" />                    
                    </div>
                    <div class="serviceDesc">
                        <h3 style="font-size:20px; font-weight:600; text-transform: capitalize;">{{a.firstName+' '+a.lastName}}</h3>
                        <i>{{a.serviceName}}</i><br />
                        {{a.location}}<br />
                        <strong>Service Length:</strong> {{a.serviceLength}}<br />
                        <strong>{{a.type==1? 'Offer': 'Request'}} Id:</strong> {{a.userServiceId}}<br />
                        <strong>Service Status:</strong> {{a.status==1?'New':''}}{{a.status==2?'In Progress':''}}{{a.status==3?'Completed':''}}<br />
                        {{a.description}}
                        <div class="divfiles" *ngIf="a.docs"><a *ngFor="let url of fetchUrls(a.docs)" class="files" style="margin-right: 10px;" target="_blank" [href]="url.fullUrl"><img src="../assets/images/file.png" style="max-width:15px; margin-right:3px;" />{{url.name}}</a></div>
                    </div>
                    
                    <div class="serviceCTAs" style="font-family: 'Open Sans Condensed', Arial; font-size:15px; font-weight:bold;" *ngIf="!a.requesterFirstName">
                        <div *ngIf="!a.requesterFirstName" style="color:red; border:1px #f00 solid; padding:5px; float:left; margin-right:5px;">
                            <a style="text-decoration: none; color:red;" *ngIf="a.type==1 && !a.requesterFirstName" routerLink='/offer/{{a.userServiceId}}'>Edit</a>
                            <a style="text-decoration: none; color:red;" *ngIf="a.type==2 && !a.requesterFirstName" routerLink='/request/{{a.userServiceId}}'>Edit</a>
                        </div>
                        <div *ngIf="!a.requesterFirstName && a.status!=2" style="color:red; border:1px #f00 solid; padding:5px; float:left">
                            <a *ngIf="!a.requesterFirstName && a.status!=2" href="javascript:void(0)" style="text-decoration: none; color:red;" (click)="deleteMyService(a.userServiceId)">Delete</a>
                        </div>
                    </div>
    
                </div>                
            </div>            
        </div>
        <br />
        <h3>SERVICES REQUESTED BY ME</h3> <br />
        <div class="Requested">
            <div class="searchBar clearfix">
                <div style="float:right">
                    <input type="text" placeholder="Search" class="searchInput" (change)="updateServices(2)" [(ngModel)]="serviceSearchInputRequested" />
                    <select class="searchSelect" (change)="updateServices(2)" [(ngModel)]="servicetyperequested">
                        <option value="">All Service Types</option>
                        <option *ngFor="let a of serviceTypes" value="{{a.serviceId}}">{{a.serviceName}}</option>
                    </select>
                </div>
                <div style="float:left; margin-top:5px;">TOTAL {{myrequested?myrequested.length:0}} REQUESTED {{myrequested && myrequested.length==1?'SERVICE' : 'SERVICES'}} FOUND</div>    
            </div>        
            <div class="panel-separator"></div>
            <div class="servicesList clearfix">
                <div class="serviceItem" *ngFor="let a of myrequested">
                    <div class="serviceImage">
                        <img src="{{a.profileImage?a.profileImage:'/assets/images/default-profile.jpg'}}" style="max-height:128px; max-width:128px" />                    
                    </div>
                    <div class="serviceDesc">
                        <h3 style="font-size:20px; font-weight:600; text-transform: capitalize;">{{a.firstName+' '+a.lastName}}</h3>
                        <i>{{a.serviceName}}</i><br />
                        {{a.location}}<br />
                        <strong>Service Length:</strong> {{a.serviceLength}}<br />
                        <strong>{{a.type==1? 'Offer': 'Request'}} Id:</strong> {{a.userServiceId}}<br />
                        <strong>Service Status:</strong> {{a.status==1?'New':''}}{{a.status==2?'In Progress':''}}{{a.status==3?'Completed':''}}{{a.status==4?'Cancelled':''}}<br />
                        <span *ngIf="a.status==3"><strong>Notes:</strong>{{a.cancellationReason}}</span><br />
                        {{a.description}}
                        <div class="divfiles" *ngIf="a.docs"><a *ngFor="let url of fetchUrls(a.docs)" class="files" style="margin-right: 10px;" target="_blank" [href]="url.fullUrl"><img src="../assets/images/file.png" style="max-width:15px; margin-right:3px;" />{{url.name}}</a></div>
                    </div>
                    
                    <div class="serviceCTAs" style="font-family: 'Open Sans Condensed', Arial; font-size:15px; font-weight:bold;" *ngIf="!a.requesterFirstName">
                        <div *ngIf="!a.requesterFirstName" style="color:red; border:1px #f00 solid; padding:5px; float:left; margin-right:5px;">
                            <a style="text-decoration: none; color:red;" *ngIf="a.type==1 && !a.requesterFirstName" routerLink='/offer/{{a.userServiceId}}'>Edit</a>
                            <a style="text-decoration: none; color:red;" *ngIf="a.type==2 && !a.requesterFirstName" routerLink='/request/{{a.userServiceId}}'>Edit</a>
                        </div>
                        <div *ngIf="!a.requesterFirstName && a.status!=2" style="color:red; border:1px #f00 solid; padding:5px; float:left">
                            <a *ngIf="!a.requesterFirstName && a.status!=2" href="javascript:void(0)" style="text-decoration: none; color:red;" (click)="deleteMyService(a.userServiceId)">Delete</a>
                        </div>
                    </div>
    
                </div>
                
            </div>
        </div>
    </section>    
</div>
