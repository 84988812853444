import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { Router, ActivatedRoute } from "@angular/router";


@Component({
  selector: 'app-admin-servciestypes',
  templateUrl: './admin-servicestypes.component.html',
  styleUrls: ['./admin-servicestypes.component.css','../admin-parent/bootstrap.css']
})
export class AdminServicesTypesComponent implements OnInit {
  servicetypes:any;
  constructor(private service:ServiceService, private route:Router, private activatedroute:ActivatedRoute) { }
  ngOnInit(): void {
    this.service.fetchservicetypes(true).subscribe((data:any)=>{
      console.log("data==>", data)
      this.servicetypes=data.serviceTypes;
    })
    
    /*this.service.fetchservicesadmin(filters).subscribe((services:any)=>{
      this.services = services.services;
      console.log("users==>", services.services)
    })*/
  }

  /*deleteService(serviceId) {
    if(confirm("Are you sure you want to delete this service?")){
      let deletefilters='';
      deletefilters+='serviceId='+serviceId
      
      this.service.deleteserviceadmin(deletefilters).subscribe((services:any)=>{
        this.service.fetchuseradmin(this.userId).subscribe((data:any)=>{
          console.log("data==>", data)
          this.userdetails=data.user;
        })
        let filters='';
          filters+='id='+this.userId
        
        this.service.fetchservicesadmin(filters).subscribe((services:any)=>{
          this.services = services.services;
          console.log("users==>", services.services)
        })
      })
    }    
  }*/  
}
