import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AdminUsersComponent } from '../admin-users/admin-users.component';
import { ServiceService } from '../services/service.service';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-admin-offer-service',
  templateUrl: './admin-offer-service.component.html',
  styleUrls: ['./admin-offer-service.component.css']
})
export class AdminOfferServiceComponent implements OnInit {
  serviceTypeClass= { text:true, textBox:true, error:false };
  serviceTypeError = { errorMessage:true, none:true };
  descriptionClass= { text:true, textArea:true, error:false };
  descriptionError = { errorMessage:true, none:true };
  phoneClass= { text:true, textBox:true, error:false };
  phoneError = { errorMessage:true, none:true };
  locationClass= { text:true, textBox:true, error:false };
  locationError = { errorMessage:true, none:true };
  serviceStatusClass = { text:true, textBox:true, error:false };
  serviceStatusError = { errorMessage:true, none:true };
  cancellationReasonClass= { text:true, textBox:true, smallTextArea:true, error:false };
  cancellationReasonError = { errorMessage:true, none:true };
  serviceTypes:any;
  userdetails:any;
  username='';
  offer={
    serviceType:'',
    description:'',
    location:'',
    phone:'',
    docs:'',
    status:'',
    price:0,
    isAdmin:1,
    userId:'',
    serviceLength:'',
    serviceId:'',
    type:'1',
    cancellationReason:''
  }
  userId='';
  docs=[];
  constructor(private service:ServiceService, private router:Router, private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('id');
    this.offer.userId = this.route.snapshot.paramMap.get('id');
    this.service.fetchservicetypes(true).subscribe((data:any)=>{
      this.serviceTypes=data.serviceTypes;
    })

    this.service.fetchuseradmin(this.offer.userId).subscribe((data:any)=>{
      this.userdetails=data.user;
      this.offer.phone = this.userdetails.phone;
    })

    if(this.route.snapshot.paramMap.get('serviceId')){
      let serviceId = this.route.snapshot.paramMap.get('serviceId');
      this.offer.serviceId = serviceId;
      let filters='';
      filters+='id='+this.userId
      this.service.fetchservicesadmin(filters).subscribe((data:any)=>{
        console.log("data==>", data)
        for(let a of data.services){
          if(a.userServiceId==serviceId){
            //this.myService=a;
            this.offer.serviceType = a.serviceType;
            this.offer.description = a.description;
            this.offer.location = a.location;
            this.offer.phone = a.phone;
            this.offer.docs = a.docs;
            this.offer.status = a.status;
            this.offer.price = a.price;
            this.offer.serviceLength = a.serviceLength;
            this.offer.type = a.type;
            if(this.offer.type=='2'){
              this.offer.cancellationReason = a.cancellationReason;
            }            
            this.docs = a.docs?JSON.parse(a.docs):[];
          }
        }
        
      })
    }

  }

  deleteDoc(index){
    this.docs.splice(index, 1)
    this.offer.docs = JSON.stringify(this.docs);
  }

  fileUploaded(event){
    let data = {};
    let files = event.srcElement.files;
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(i.toString(), files[i], files[i].name);
    }
    formData.append("data", JSON.stringify(data));
    this.service.postImage(formData, true).subscribe((fileUpload:any)=>{
      this.docs.push({fullUrl:environment.baseUrl+fileUpload.data, name:fileUpload.name});
      this.offer.docs = JSON.stringify(this.docs);
    });
  }

  validateForm(){
    console.log("this.offer.status==>", this.offer.status, !this.offer.status)
    if(!this.offer.serviceType){
      this.serviceTypeClass.error=true;
      this.serviceTypeError.none=false;
    }else{
      this.serviceTypeClass.error=false;
      this.serviceTypeError.none=true;
    }
    if(!this.offer.location){
      this.locationClass.error=true;
      this.locationError.none=false;
    }else{
      this.locationClass.error=false;
      this.locationError.none=true;
    }
    if(!this.offer.phone){
      this.phoneClass.error=true;
      this.phoneError.none=false;
    }else{
      this.phoneClass.error=false;
      this.phoneError.none=true;
    }    
    if(!this.offer.description){
      this.descriptionClass.error=true;
      this.descriptionError.none=false;      
    }else{
      this.descriptionClass.error=false;
      this.descriptionError.none=true;
    }
    if(!this.offer.status){
      this.serviceStatusClass.error=true;
      this.serviceStatusError.none=false;
    } else {
      this.serviceStatusClass.error=false;
      this.serviceStatusError.none=true;
    }
    if(!this.offer.cancellationReason){
      this.cancellationReasonClass.error=true;
      this.cancellationReasonError.none=false;
    } else {
      this.cancellationReasonClass.error=false;
      this.cancellationReasonError.none=true;
    }
  }

  submit(){
    if(!this.offer.serviceType){
      this.serviceTypeClass.error=true;
      this.serviceTypeError.none=false;
    }else{
      this.serviceTypeClass.error=false;
      this.serviceTypeError.none=true;
    }
    if(!this.offer.location){
      this.locationClass.error=true;
      this.locationError.none=false;
    }else{
      this.locationClass.error=false;
      this.locationError.none=true;
    }
    if(!this.offer.phone){
      this.phoneClass.error=true;
      this.phoneError.none=false;
    }else{
      this.phoneClass.error=false;
      this.phoneError.none=true;
    }    
    if(!this.offer.description){
      this.descriptionClass.error=true;
      this.descriptionError.none=false;      
    }else{
      this.descriptionClass.error=false;
      this.descriptionError.none=true;
    }
    if(!this.offer.status){
      this.serviceStatusClass.error=true;
      this.serviceStatusError.none=false;
    } else {
      this.serviceStatusClass.error=false;
      this.serviceStatusError.none=true;
    }
    if(!this.offer.cancellationReason){
      this.cancellationReasonClass.error=true;
      this.cancellationReasonError.none=false;
    } else {
      this.cancellationReasonClass.error=false;
      this.cancellationReasonError.none=true;
    }

    if(!this.offer.serviceType || !this.offer.location || !this.offer.phone || !this.offer.description || !this.offer.status){
      return
    }
    
    if(this.route.snapshot.paramMap.get('serviceId')){
      this.offer.serviceId = this.route.snapshot.paramMap.get('serviceId');
      this.service.updateService(this.offer).subscribe((data:any)=>{
        if(data.status=='success'){
          this.router.navigate(['admin/services/'+this.route.snapshot.paramMap.get('id')]);
        }
      })
    } else {
      this.service.saveoffer(this.offer).subscribe((data:any)=>{
        if(data.status=='success'){
          this.router.navigate(['admin/services/'+this.route.snapshot.paramMap.get('id')]);
        }
      })
    }    
  }

}
