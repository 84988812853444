<h1 style="clear:both; text-align: center; font-family: 'Open Sans Condensed', Arial;" *ngIf="userdetails">{{userdetails.firstName+' '+userdetails.lastName}}'s Services</h1>
<div style="margin: 15px 0; float:right"><input *ngIf="userId" type="button" value="Offer a Service" routerLink='/admin/services/offerservice/{{userId}}' class="submitBtn" style="color: #fff; font-family: 'Open Sans Condensed', Arial;"></div>

<div style="clear:both">
    <h3 style="float:left"><a routerLink="/admin">Dashboard</a> > <a *ngIf="userId" routerLink="/admin/users">Users</a> {{userId?'>':''}} Services</h3>
    <div style="float:right; margin-top:20px;">
        Service Type: 
        <select [(ngModel)]="typeFilter" (change)="filterServices()">
            <option value="0">All</option>
            <option value="1">Offered Services</option>
            <option value="2">Requested Services</option>
        </select>
    </div>
</div>
<table class="table">
    <thead class="thead-dark">
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th *ngIf="!userId" scope="col">Service Name</th>
            <th scope="col">Description</th>
            <th scope="col">Type</th>
            <th scope="col">Phone</th>            
            <th scope="col">Price</th>
            <th scope="col">Location</th>
            <th scope="col">Created On</th>
            <th scope="col">Actions</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let service of filteredServices; let i=index">
            <th scope="row">{{i+1}}</th>
            <td *ngIf="!userId">{{service.firstName +' '+service.lastName}}</td>
            <td>{{service.serviceName}}</td>
            <td>{{service.description}}</td>
            <td>{{service.type==1?"Offer":"Request"}}</td>
            <td>{{service.phone}}</td>
            <td>${{service.price}}</td>
            <td>{{service.location}}</td>
            <td>{{service.createdAt.split('T')[0].split('-')[1]+'/'+service.createdAt.split('T')[0].split('-')[2]+'/'+service.createdAt.split('T')[0].split('-')[0]}}</td>
            <td *ngIf="userId"><a routerLink='/admin/services/editservice/{{userId}}/{{service.userServiceId}}'>Edit</a> |&nbsp; <a href="javascript:void(0)" (click)="deleteService(service.userServiceId)">Delete</a></td>
            <td *ngIf="!userId"><a routerLink='/admin/services/editservice/{{service.userId}}/{{service.userServiceId}}'>Edit</a> |&nbsp; <a href="javascript:void(0)" (click)="deleteService(service.userServiceId)">Delete</a></td>
        </tr>
    </tbody>
</table>