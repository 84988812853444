import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  userId:string;
  sub:any;
  passwordClass={textBox:true, error:false};
  passwordLabel={errorMessage:true, none: true}
  confirmClass={textBox:true, error:false};
  confirmLabel={errorMessage:true, none: true}
  withError = {textBox:true, error:true};
  withoutError = {textBox:true, error:false};
  apiError=' ';
  apiSuccess='';
  user={
    password:'',
    confirm:'',
    userId:''
  }

  constructor(private route: ActivatedRoute, private service:LoginService, private router:Router) { }

  ngOnInit():void {
    this.sub = this.route.params.subscribe(params => {
      console.log("params user==>", params)
      this.userId = atob(decodeURIComponent(params['userid']));
      console.log("this.userId==>", this.userId);
      this.service.validateRefresh({userId:this.userId}).subscribe((data:any)=>{
        if(data.status=='failure'){
          this.apiError = data.message;
        } else {
          this.apiError='';
          this.user.userId = this.userId;
        }
      })
    });
  }

  login(){  
    console.log("In Login")  
    if(!this.user.password){
      this.passwordClass=this.withError;
      return;
    }else{
      this.passwordClass=this.withoutError;
    }
    if(!this.user.confirm){
      this.confirmClass=this.withError;
      this.confirmLabel.none = true;
      return;
    }else{
      if(this.user.password!=this.user.confirm){
        this.confirmClass=this.withError;
        this.confirmLabel.none = false;
        return;
      } else{
        this.confirmClass=this.withoutError;
        this.confirmLabel.none = true;
      }
    }
    this.service.resetPassword(this.user).subscribe((token:any) => {
      console.log("token==>", token.status)
      if(token && token.status=='success'){
        this.apiSuccess = token.message;
        this.user.password=''
        this.user.confirm=''
        //localStorage.setItem('token', token.token);
        //this.router.navigate(['dashboard'])
      }else{
        this.apiError = token.message;
        this.apiSuccess = ''
      }
    })
  }

  validateLogin(event){
    console.log(event.target.id)
    if(event.keyCode!=13){
      if(event.target.id=='password'){
        if(!this.user.password){
          this.passwordClass=this.withError;
        }else{
          this.passwordClass=this.withoutError;
        }
      } else if(event.target.id=='confirm'){
        if(!this.user.confirm){
          this.confirmClass=this.withError;
          this.confirmLabel.none = true;
        }else{
          if(this.user.password!=this.user.confirm){
            this.confirmClass=this.withError;
            this.confirmLabel.none = false;
          } else{
            this.confirmClass=this.withoutError;
            this.confirmLabel.none = true;
          }
        }
      }
    } else {
      this.login();
    }
  }

}
