
<div id="worship-and-sermons" class="pane-content">    
    <section class="panel-pane pane-views-panes pane-ministry-panel-pane-1 block clearfix">
        <app-login-header [title]='"OFFERED SERVICES"'></app-login-header>
        <h3 style="text-align:center">To book a service, please call or email the provider.</h3>
        <div class="searchBar clearfix">
            <div style="float:right">
                <input type="text" placeholder="Search" class="searchInput" (change)="updateServices()" [(ngModel)]="serviceSearchInput" />
                <select class="searchSelect" (change)="updateServices()" [(ngModel)]="servicetype" style="margin-right:20px;">
                    <option value="">All Service Types</option>
                    <option *ngFor="let a of serviceTypes" value="{{a.serviceId}}">{{a.serviceName}}</option>
                </select>
                <select class="searchSelect" [(ngModel)]="servicestatus" (change)="updateServices()">
                    <option value="">All Service Statuses</option>
                    <option value="1">New Services</option>
                    <option value="2">In Progress Services</option>
                    <option value="3">Completed Services</option>
                </select>
            </div>
            <div style="float:left; margin-top:5px;">TOTAL {{offerLength?offerLength:0}} {{offerLength && offerLength==1?'SERVICE' : 'SERVICES'}} FOUND</div>    
        </div>                
        <div class="panel-separator"></div>
        <div class="servicesList clearfix">
            <div class="serviceItem" *ngFor="let a of offeredServices| sort:'asc':'serviceName'">
                <div *ngIf="a.serviceName.toLowerCase()!='FOR SALE/DONATION'.toLowerCase()">
                    <div class="serviceImage"><img src="{{a.profileImage?a.profileImage:'/assets/images/default-profile.jpg'}}" style="max-height:128px; max-width:128px" /></div>
                    <div class="serviceDesc">
                        <strong><i>{{a.serviceName}}</i></strong><br />
                        <h3 style="font-size:20px; font-weight:600; text-transform: capitalize;">{{a.firstName+' '+a.lastName}}</h3>
                        {{a.location}}<br />
                        <strong>Phone:</strong> {{a.phone | mask:'(000)-000-0000'}}<br />
                        <strong>Email:</strong> {{a.email}}<br />
                        <strong>Service Length:</strong> {{a.serviceLength}}<br />
                        <strong>{{a.type==1? 'Offer': 'Request'}} Id:</strong> {{a.userServiceId}}<br />
                        <strong>Service Status:</strong> {{a.status==1?'New':''}}{{a.status==2?'In Progress':''}}{{a.status==3?'Completed':''}}<br />
                        <span class="servicedescription">{{a.description}}</span>
                        <div class="divfiles" *ngIf="a.docs">
                            <a *ngFor="let url of fetchUrls(a.docs)" class="files" style="margin-right: 10px;" target="_blank" [href]="url.fullUrl">
                                <img src="../assets/images/file.png" style="max-width:15px; margin-right:3px;" />
                                {{url.name}}
                            </a>
                        </div>
                    </div>
                    <div class="serviceCTAs">
                        <!--<input type="button" class="chatBtn grey" value="" />
                        <input type="button" class="bookBtn grey" value="" />-->
                    </div>
                </div>
            </div>
            <div class="serviceItem" *ngFor="let a of offeredServicesOther| sort:'asc':'serviceName'">
                <div *ngIf="a.serviceName.toLowerCase()!='FOR SALE/DONATION'.toLowerCase()">
                    <div class="serviceImage"><img src="{{a.profileImage?a.profileImage:'/assets/images/default-profile.jpg'}}" style="max-height:128px; max-width:128px" /></div>
                    <div class="serviceDesc">
                        <strong><i>{{a.serviceName}}</i></strong><br />
                        <h3 style="font-size:20px; font-weight:600; text-transform: capitalize;">{{a.firstName+' '+a.lastName}}</h3>
                        {{a.location}}<br />
                        <strong>Phone:</strong> {{a.phone | mask:'(000)-000-0000'}}<br />
                        <strong>Email:</strong> {{a.email}}<br />
                        <strong>Service Length:</strong> {{a.serviceLength}}<br />
                        <strong>{{a.type==1? 'Offer': 'Request'}} Id:</strong> {{a.userServiceId}}<br />
                        <strong>Service Status:</strong> {{a.status==1?'New':''}}{{a.status==2?'In Progress':''}}{{a.status==3?'Completed':''}}<br />
                        <span class="servicedescription">{{a.description}}</span>
                        <div class="divfiles" *ngIf="a.docs">
                            <a *ngFor="let url of fetchUrls(a.docs)" class="files" style="margin-right: 10px;" target="_blank" [href]="url.fullUrl">
                                <img src="../assets/images/file.png" style="max-width:15px; margin-right:3px;" />
                                {{url.name}}
                            </a>
                        </div>
                    </div>
                    <div class="serviceCTAs">
                        <!--<input type="button" class="chatBtn grey" value="" />
                        <input type="button" class="bookBtn grey" value="" />-->
                    </div>
                </div>
            </div>
            <!--<div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">KATIE ROMARIO GRIFFIN</h3>
                    <i>Onsite Help</i><br />
                    #2285, First Floor, Sector 71, Mohali<br />
                </div>
                <div class="serviceCTAs">
                    <input type="button" class="chatBtn" value="" />
                    <input type="button" class="bookBtn" value="" />
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                    <input type="button" class="chatBtn" value="" />
                    <input type="button" class="bookBtn" value="" />
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                    <input type="button" class="chatBtn" value="" />
                    <input type="button" class="bookBtn" value="" />
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                    <input type="button" class="chatBtn" value="" />
                    <input type="button" class="bookBtn" value="" />
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                    <input type="button" class="chatBtn" value="" />
                    <input type="button" class="bookBtn" value="" />
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                    <input type="button" class="chatBtn" value="" />
                    <input type="button" class="bookBtn" value="" />
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                    <input type="button" class="chatBtn" value="" />
                    <input type="button" class="bookBtn" value="" />
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                    <input type="button" class="chatBtn" value="" />
                    <input type="button" class="bookBtn" value="" />
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                    <input type="button" class="chatBtn" value="" />
                    <input type="button" class="bookBtn" value="" />
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                    <input type="button" class="chatBtn" value="" />
                    <input type="button" class="bookBtn" value="" />
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                    <input type="button" class="chatBtn" value="" />
                    <input type="button" class="bookBtn" value="" />
                </div>
            </div>
            <div class="serviceItem">
                <div class="serviceImage"><img src="/assets/images/profile.png" /></div>
                <div class="serviceDesc">
                    <h3 style="font-size:20px; font-weight:600">ROMARIO GRIFFIN</h3>
                    <i>Handyman Services</i><br />
                    Inside Jalori Gate, Jodhpur<br />
                </div>
                <div class="serviceCTAs">
                    <input type="button" class="chatBtn" value="" />
                    <input type="button" class="bookBtn" value="" />
                </div>
            </div>-->
        </div>
    </section>    
</div>

<div class="spinner" [ngClass]="spinnerStatusClass"></div>
<div class="spinneroverlay" [ngClass]="spinnerStatusClass"></div>
<div class="toast" [ngClass]="toastStatusClass">
    <div class="toast-header">
      <strong class="mr-auto text-primary">Offer Booked Successfully</strong>
      <small class="text-muted"></small>
      <button type="button" class="ml-2 mb-1 close" (click)="hideToast()" >&times;</button>
    </div>
</div>
