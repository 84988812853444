import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../services/service.service';

@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.css']
})
export class MyBookingsComponent implements OnInit {
  servicetype='';
  serviceSearchInput='';
  myServices:any;  
  originalMyServices:any;
  serviceTypes:any;
  bookingType='';
  constructor(private service:ServiceService) { }
  currentUserId;
  ngOnInit(): void {
    this.service.fetchservicetypes().subscribe((data:any)=>{
      console.log("data==>", data)
      this.serviceTypes=data.serviceTypes;
    })
    this.service.mybookings().subscribe((data:any)=>{
      console.log("data==>", data)
      this.myServices=data.services;
      this.originalMyServices = JSON.parse(JSON.stringify(data.services));
    })
    this.currentUserId = JSON.parse(localStorage.getItem('user')).userId;
    console.log("currentUserId==>", this.currentUserId);
  }

  fetchUrls(json){
    console.log(JSON.parse(json));
    return JSON.parse(json);
  }

  markcomplete(serviceId, bookingId){
    this.service.updateBooking({serviceId:serviceId, bookingId:bookingId, status:3}).subscribe((data:any)=>{
      this.service.mybookings().subscribe((data:any)=>{
        console.log("data==>", data)
        this.myServices=data.services;
        this.originalMyServices = JSON.parse(JSON.stringify(data.services));
      })
    })
  }

  updateServices(){
    
    if(this.bookingType && this.bookingType=='Booked By'){
      this.myServices = JSON.parse(JSON.stringify(this.originalMyServices));
      this.myServices = this.myServices.filter((a)=>{
        return this.currentUserId==a.offerer_id;
      })
    } else if(this.bookingType && this.bookingType=='Offered By'){
      this.myServices = JSON.parse(JSON.stringify(this.originalMyServices));
      this.myServices = this.myServices.filter((a)=>{
        return this.currentUserId==a.requester_id;
      })
    } else {
      this.myServices = JSON.parse(JSON.stringify(this.originalMyServices));
    }
  }
}
