<div id="worship-and-sermons" class="pane-content">
    <div class="panel-pane pane-block pane-block-14 no-title block">        
        <div style="margin-bottom:55px; width:100%">
            <h1 class="node-title" style="text-align: center; font-family:'Open Sans Condensed', Arial"> RESET PASSWORD </h1>
        </div>
        <div class="loginPanel clearfix" *ngIf="!apiError">
            <div class="loginForm">
                <div class="errorMessage" style="color: green; text-align: center; text-transform: none; margin-top:20px;" *ngIf="apiSuccess">{{apiSuccess}}</div>
                <div class="elementMargin">
                    
                    <div class="firstDiv">
                        <div class="textlabel">Password</div>
                        <input type="password" [ngClass]="passwordClass" (keyup)="validateLogin($event)" id="password" [(ngModel)]='user.password' placeholder="Enter Password" />
                    </div>
                    <div [ngClass]="passwordLabel">Please enter password!</div>
                   

                    <div>
                        <div class="textlabel">Confirm Password</div>
                        <input type="password" [ngClass]="confirmClass" (keyup)="validateLogin($event)" id="confirm" [(ngModel)]='user.confirm' placeholder="Confirm Password" />
                    </div>
                    <div [ngClass]="confirmLabel">Password and Confirm Password doesn't match!</div>
                    

                    <div style="margin-top: 23px;" class="sermon-cta-buttons">
                        <input type="button" (click)="login()" class="submitBtn" value="RESET PASSWORD" style="float: left;" />         
                        <a class="itunes-btn" [routerLink]="['/login']">Back to Login</a>                       
                    </div>
                    
                </div>
                
            </div>            
        </div>
        <div class="elementMargin errorMessage" style="text-align: center;" *ngIf="apiError">{{apiError}}</div>
    </div>
</div>
