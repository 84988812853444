import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  userId:String;
  sub:any;
  apiError='';

  
  emailClass={textBox:true, error:false};
  emailLabel={errorMessage:true, none: true}
  user={
    email:''
  }
  disabled=false;

  constructor(private service:LoginService) { }

  ngOnInit():void {
    
  }

  login(){
    if(!this.user.email){
      this.emailClass.error=true;
      this.emailLabel.none=false;
      return;
    } else {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email)){        
        this.emailLabel.none=false;
        this.emailClass.error=true;
        return;
      }
    }    
    this.user.email = this.user.email.toLowerCase();
    this.service.forgotPassword(this.user).subscribe((token:any) => {
      console.log("token==>", token.status)
      if(token && token.status=='success'){
        this.apiError=token.message;
        this.user.email='';
      }
    })
  }

  validateLogin(event){
    console.log(event.target.id)
    console.log("event.keyCode==>", event.keyCode)
    if(event.keyCode!=13){
      if(this.user.email){
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email)){
          this.emailLabel.none=true;
          this.emailClass.error=false;
        }else{
          this.emailLabel.none=false;
          this.emailClass.error=true;
        }
      }else{
        this.emailClass.error=true;
      }
    } else {  
      this.login();
    }
    
  }

}
