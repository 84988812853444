<style>
    @media screen and (min-width: 1025px) {
        #worship-and-sermons {
            margin-top: 42px;
        }
        .pane-content-title{
            margin-top:-75px;
        }
        .pane-page-content{
            margin-top:42px;
        }
    }
    @media screen and (max-width: 1024px) {
        .pane-content h2, .pane-content p {
            margin-left:20px;
            margin-right:20px;
        }
    }
    .sermon-cta-buttons{
        width:199px;
        margin: 0 auto;
    }
    a.itunes-btn{
        margin-right:0px !important;
    }
</style>
<div class="panel-pane pane-page-content no-title block">
    <div class="pane-inner clearfix">
      
              
      
      <div class="pane-content">
        <div data-quickedit-entity-id="node/71"><div class="one-column at-panel panel-display clearfix">
    <div class="region region-one-main">
      <div class="region-inner clearfix">
        <div class="panel-pane pane-views-panes pane-header-slider-panel-pane-1 no-title block">
    <div class="pane-inner clearfix">
      
              
      
      <div class="pane-content">
        <div class="view view-header-slider view-id-header_slider view-display-id-panel_pane_1 view-dom-id-87b0ec2f1bb41ac70e4e92cdfc6223d1">
          
    
    
        <div class="view-content">
        
    <div class="skin-default views-slideshow-cycle-processed">
      
      <div id="views_slideshow_cycle_main_header_slider-panel_pane_1" class="views_slideshow_cycle_main views_slideshow_main viewsSlideshowCycle-processed"><div id="views_slideshow_cycle_teaser_section_header_slider-panel_pane_1" class="views-slideshow-cycle-main-frame views_slideshow_cycle_teaser_section">
    <div id="views_slideshow_cycle_div_header_slider-panel_pane_1_0" class="views-slideshow-cycle-main-frame-row views_slideshow_cycle_slide views_slideshow_slide views-row-1 views-row-first views-row-odd" aria-labelledby="views_slideshow_pager_field_item_bottom_header_slider-panel_pane_1_0">
    <div class="views-slideshow-cycle-main-frame-row-item views-row views-row-0 views-row-odd views-row-first">
      
    <div class="views-field views-field-field-header-image">        <div class="field-content"><picture>
  
  <img src="https://cedarlane.org/sites/default/files/styles/header_slide_image_narrow_custom_user_desktop_wide_2x/public/2017cedarlane-4049_0.jpg?itok=7aqe0hAM&amp;timestamp=1562352833" alt="" title="">
  </picture></div>  </div></div>
  </div>
  </div>
  </div>
        </div>
      </div>
      <div class="panel-pane pane-entity-view pane-node pane-content-title no-title block">
        <div class="pane-inner clearfix">
          
                  
          
          <div class="pane-content">
            <article id="node-71" class="node node-page article odd node-title-display clearfix" about="/visit-sundays" typeof="foaf:Document" role="article">
        
            <header class="node-header">
                    <h1 class="node-title">
                        Cedar Lane Marketplace                  
                    </h1>
                </header>
        
        
        <div class="node-content">
            </div>
      
        
        
        <span property="dc:title" content="Visit on Sundays" class="rdf-meta element-hidden"></span></article>
          </div>
      
          
              
        </div>
      </div>
    <div id="worship-and-sermons" class="pane-content">
        <div class="panel-pane pane-block pane-block-14 no-title block">
            <div class="pane-inner clearfix">
                <div class="pane-content" [innerHTML]="cmsContent">

                  <!--<p>Welcome to the Cedar Lane Marketplace (CLM), a new way to nurture our Cedar Lane community that matches members’ needs for services with members who have skills, talents, and professional expertise to offer. Need a dentist, landscape design, a math tutor, IT assistance or music lessons? Look no further for these and many other services available from members of your church community.</p>
                  <p>There are several good reasons to use the Marketplace. The first is quick and easy access to needed services from people who are a part of your faith community. Buyers will be supporting the careers and talents of fellow church members. Secondly, the CLM can be an effective marketing portal for providers. Hopefully, the potential for new friendships will be a side effect of the process.</p>
                  <p>And here is one additional key component of the Marketplace - it is a source of much needed income for Cedar Lane. The provider is asked to make a good faith donation of 10 percent of the transaction value to Cedar Lane. </p>
                  <p>Ready to get started? Click here to post requests for services or to offer your expertise. An administrator will monitor content posted on the CLM, authorize participants, and track status and completion of projects.</p>
                  <p>Questions? Contact the CLM administrator at <a href="mailto:marketplace@cedarlane.org">marketplace@cedarlane.org</a>.</p>
                  -->
                    <!--<p>Welcome to the Cedar Lane Marketplace (CLM), a new way to nurture our Cedar Lane community that matches members’ needs for services and expertise with members who have skills, talents, and professional expertise to offer. Need a dentist, landscape design, a math tutor, IT assistance or music lessons? Look no further for these and many other services available from members of your church community</p>
                    <p>There are several good reasons to use the CLM. The first is quick and easy access to needed services from people who are a part of your faith community. Requesters will be supporting the careers and talents of fellow church members. Secondly, the CLM can be an effective marketing portal for providers. Hopefully, the potential for new friendships will be a side effect of the process.</p> 
                    <p>And here is one additional key component of the CLM - it is a source of much needed income for Cedar Lane. The provider, who receives a monetary benefit as a result of the CLM, will be asked to make a good faith donation of 10 percent of the transaction value to Cedar Lane. The provider may price some or all of this donation as part of the cost of the service.</p>
                    <p>Ready to get started? Click here to post requests for support or to offer your services. An administrator will monitor content posted on the CLM, authorize participants, and track status and completion of projects</p> 
                    <p>Questions? Contact the CLM administrator at———-.</p>   -->
                </div>
            </div>
            
              <h1 style="font-weight:100 !important; text-align: center; font-family:'Open Sans Condensed', Arial" >
                  CEDAR LANE MARKETPLACE                  
              </h1>
              <div style="border-bottom:2px solid #d4d4d4; width:100%"></div>
            <div style="padding-top:30px; width: auto; overflow:auto; margin:0 auto;"> 
                <div class="sermon-cta-buttons">
                    <a [routerLink]="['/login']">Login</a>
                    <a [routerLink]="['/register']" class="itunes-btn">Sign Up</a>
                </div>
            </div>
        </div>        
    </div>
  
      
          
    </div>
  </div>
 
    </div>
  </div>
  </div>    </div>
  
      
          
    </div>
  </div>
