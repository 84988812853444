import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AdminUsersComponent } from '../admin-users/admin-users.component';
import { ServiceService } from '../services/service.service';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-admin-cms',
  templateUrl: './admin-cms.component.html',
  styleUrls: ['./admin-cms.component.css']
})
export class AdminCMSComponent implements OnInit {  
  cmsClass= { text:true, textBox:true, error:false };
  cmsError = { errorMessage:true, none:true };
  serviceTypes:any;
  userdetails:any;
  username='';
  cms={
    cmsContent:'',
    cmsId:''
  }
  docs=[];
  constructor(private service:ServiceService, private router:Router, private route:ActivatedRoute) { }

  ngOnInit(): void {

    this.service.fetchcms().subscribe((data:any)=>{
      console.log("data==>", data)
      if(data.cms.cmsId){
        //this.myService=a;
        this.cms.cmsContent = data.cms.cmsContent;
        this.cms.cmsId = data.cms.cmsId;
      }  
    })
  }

  /*deleteDoc(index){
    this.docs.splice(index, 1)
    this.offer.docs = JSON.stringify(this.docs);
  }*/

  

  validateForm(){
    if(!this.cms.cmsContent){
      this.cmsClass.error=true;
      this.cmsError.none=false;
    }else{
      this.cmsClass.error=false;
      this.cmsError.none=true;
    }    
  }

  submit(){
    if(!this.cms.cmsContent){
      this.cmsClass.error=true;
      this.cmsError.none=false;
      return;
    }else{
      this.cmsClass.error=false;
      this.cmsError.none=true;
    }  
    
    
    this.service.updatecms(this.cms).subscribe((data:any)=>{
      if(data.status=='success'){
        alert("CMS Content Updated")
        //this.router.navigate(['admin/']);
      }
    })
  }

}
